import { createAction, props } from '@ngrx/store';

import {
  IPageOfPropertyInput,
  IPageOfProperty,
  IProperty,
} from '@shared/models/properties';
import { IOffer } from '@shared/models/offers';
import { IPropertyProForma } from '@shared/models/pro-forma';

export const resetPropertiesAction = createAction('[Properties] Reset');

export const fetchPropertiesAction = createAction(
  '[Properties] Fetch Properties',
  props<IPageOfPropertyInput>()
);

export const fetchPropertiesActionSuccess = createAction(
  '[Properties] Fetch Properties Success',
  props<IPageOfProperty>()
);

export const fetchPropertiesActionFailure = createAction(
  '[Properties] Fetch Properties Failure'
);

export const fetchPropertyAction = createAction(
  '[Properties] Fetch Property',
  props<{ id: string; refCode?: string; source?: string }>()
);

export const fetchPropertyActionSuccess = createAction(
  '[Properties] Fetch Property Success',
  props<{ property: IProperty }>()
);

export const fetchPropertyActionFailure = createAction(
  '[Properties] Fetch Property Failure'
);

export const clearPropertyAction = createAction('[Properties] Clear Property');

export const fetchPropertyOffer = createAction(
  '[Properties] Fetch Property Offer',
  props<IOffer>()
);

export const fetchPropertyProFormaAction = createAction(
  '[Properties] Fetch Property Pro Forma',
  props<{ propertyId: string }>()
);

export const fetchPropertyProFormaSuccess = createAction(
  '[Properties] Fetch Property Pro Forma Success',
  props<IPropertyProForma>()
);

export const fetchPropertyProFormaFailure = createAction(
  '[Properties] Fetch Property Pro Forma Failure'
);

export const clearPropertyProFormaAction = createAction(
  '[Properties] Clear Property Pro Forma'
);

export const savePropertyGuestRefAction = createAction(
  '[Properties] Save Property Guest Ref',
  props<{ propertyId: string; refCode: string; source: string }>()
);

export const setSyncingGuestReferralsAction = createAction(
  '[Properties] Set Syncing Guest Referrals',
  props<{ syncing: boolean }>()
);

export const removeGuestReferralAction = createAction(
  '[Properties] Remove Guest Referral',
  props<{ propertyId: string; refCode: string; source: string }>()
);
