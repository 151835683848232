import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { setAuthModal } from '@state/auth/auth.actions';

@Component({
  selector: 'app-auth-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignUpComponent {
  constructor(public store: Store<AppState>) {}

  openSignIn() {
    this.store.dispatch(setAuthModal({ authModal: 'signin' }));
  }
}
