import { createAction, props } from '@ngrx/store';

import { IFeatureFlag } from '@shared/models/feature-flags';

export const resetConfigAction = createAction('[Config] Reset');

export const updateOpenModalAction = createAction(
  '[Config] Update Open Modal',
  props<{ openModal?: string }>()
);

export const toggleHomeMapViewAction = createAction(
  '[Config] Toggle Home Map View'
);

export const fetchFeatureFlagsAction = createAction(
  '[Config] Fetch Feature Flags',
  props<{ keys: string[] }>()
);

export const fetchFeatureFlagsFinishAction = createAction(
  '[Config] Fetch Feature Flags Success',
  props<{ featureFlags: IFeatureFlag[] }>()
);

export const hideNoticeAction = createAction(
  '[Config] Hide Notice',
  props<{ notice: string }>()
);
