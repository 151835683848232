import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { IRange8 } from '@shared/models/range';
import { IBuyBox } from '@shared/models/buy-boxes';
import { BuyBoxesService } from '@core/services/buy-boxes.service';

import {
  listOwnBuyBoxesAction,
  listOwnBuyBoxesActionSuccess,
  listOwnBuyBoxesActionFailure,
  fetchBuyBoxAction,
  fetchBuyBoxActionSuccess,
  fetchBuyBoxActionFailure,
  createBuyBoxAction,
  createBuyBoxActionSuccess,
  createBuyBoxActionFailure,
  editBuyBoxAction,
  editBuyBoxActionSuccess,
  editBuyBoxActionFailure,
  deleteBuyBoxAction,
  deleteBuyBoxActionSuccess,
  deleteBuyBoxActionFailure,
} from './buy-boxes.actions';

@Injectable()
export class BuyBoxesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private router: Router,
    private toastr: ToastrService,
    private buyBoxesService: BuyBoxesService
  ) {}

  priceStringToNumber(price: string | null): number | null {
    if (!price) return null;
    return parseFloat(price.replace(/[,$]/g, ''));
  }

  formatBuyBox(buyBox: IBuyBox): IBuyBox {
    const propertiesToUpdate: (keyof IBuyBox)[] = [
      'beds',
      'baths',
      'sqft',
      'yearBuilt',
    ];
    // lower and upper inclusive
    propertiesToUpdate.forEach((prop) => {
      const rangeProperty = buyBox[prop] as IRange8;
      if (rangeProperty.lower !== null && !rangeProperty.lowerInclusive) {
        rangeProperty.lower += 1;
      }
      if (rangeProperty.upper !== null && !rangeProperty.upperInclusive) {
        rangeProperty.upper -= 1;
      }
    });
    // price
    if (buyBox.price) {
      if (buyBox.price.lower) {
        buyBox.price.lower = this.priceStringToNumber(buyBox.price.lower) as
          | string
          | null;
      }
      if (buyBox.price.upper) {
        buyBox.price.upper = this.priceStringToNumber(buyBox.price.upper) as
          | string
          | null;
      }
    }
    return buyBox;
  }

  listOwnBuyBoxes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(listOwnBuyBoxesAction),
      switchMap((query) =>
        this.buyBoxesService.fetchBuyBoxes(query).pipe(
          map((res) => {
            if (res?.listOwnBuyBoxes) {
              return listOwnBuyBoxesActionSuccess({
                ...res.listOwnBuyBoxes,
                results: res.listOwnBuyBoxes.results.map((result) =>
                  this.formatBuyBox(result)
                ),
              });
            } else {
              throw Error('No buy boxes found');
            }
          }),
          catchError((error) => {
            return [listOwnBuyBoxesActionFailure()];
          })
        )
      )
    )
  );

  fetchBuyBox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchBuyBoxAction),
      switchMap((query) =>
        this.buyBoxesService.fetchBuyBox(query.id).pipe(
          map((res) => {
            if (res?.listOwnBuyBoxes && res?.listOwnBuyBoxes.results.length) {
              return fetchBuyBoxActionSuccess(
                this.formatBuyBox(res?.listOwnBuyBoxes.results[0])
              );
            } else {
              throw Error('No buy box found');
            }
          }),
          catchError(() => {
            this.toastr.error('There was an error loading the buy box');
            this.router.navigate(['/buy-boxes']);
            return [fetchBuyBoxActionFailure()];
          })
        )
      )
    )
  );

  createBuyBox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createBuyBoxAction),
      switchMap((query) =>
        this.buyBoxesService.createUserBuyBox(query).pipe(
          map((res) => {
            if (res.createUserBuyBox && !res.createUserBuyBox.errors.length) {
              return createBuyBoxActionSuccess(
                this.formatBuyBox(res.createUserBuyBox.result)
              );
            } else {
              throw res.createUserBuyBox.errors;
            }
          }),
          catchError((errors) => {
            let hasBeenTaken = false;
            if (Array.isArray(errors)) {
              hasBeenTaken = errors.some(
                (error: any) =>
                  typeof error.message === 'string' &&
                  error.message.includes('has already been taken')
              );
            }
            if (hasBeenTaken) {
              this.toastr.error('The name has already been taken');
            } else {
              this.toastr.error('There was an error creating the buy box');
            }
            return [createBuyBoxActionFailure()];
          })
        )
      )
    )
  );

  createBuyBoxSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createBuyBoxActionSuccess),
        tap(() => {
          this.toastr.success('Buy Box created successfully');
          this.router.navigate(['/buy-boxes']);
        })
      ),
    { dispatch: false }
  );

  editBuyBox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editBuyBoxAction),
      switchMap((query) =>
        this.buyBoxesService.updateUserBuyBox(query).pipe(
          map((res) => {
            if (res.updateUserBuyBox && !res.updateUserBuyBox.errors.length) {
              return editBuyBoxActionSuccess(
                this.formatBuyBox(res.updateUserBuyBox.result)
              );
            } else {
              throw res.updateUserBuyBox.errors;
            }
          }),
          catchError((errors) => {
            let hasBeenTaken = false;
            if (Array.isArray(errors)) {
              hasBeenTaken = errors.some(
                (error: any) =>
                  typeof error.message === 'string' &&
                  error.message.includes('has already been taken')
              );
            }
            if (hasBeenTaken) {
              this.toastr.error('The name has already been taken');
            } else {
              this.toastr.error('There was an error editing the buy box');
            }
            return [editBuyBoxActionFailure()];
          })
        )
      )
    )
  );

  editBuyBoxAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editBuyBoxActionSuccess),
        tap(() => {
          this.toastr.success('Buy Box edited successfully');
          this.router.navigate(['/buy-boxes']);
        })
      ),
    { dispatch: false }
  );

  deleteBuyBox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteBuyBoxAction),
      switchMap((query) =>
        this.buyBoxesService.deleteUserBuyBox(query.id).pipe(
          map((res) => {
            if (res.deleteUserBuyBox && !res.deleteUserBuyBox.errors.length) {
              return deleteBuyBoxActionSuccess({ id: query.id });
            } else {
              throw res.deleteUserBuyBox.errors;
            }
          }),
          catchError(() => {
            this.toastr.error('There was an error deleting the buy box');
            return [deleteBuyBoxActionFailure()];
          })
        )
      )
    )
  );

  deleteBuyBoxSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteBuyBoxActionSuccess),
        tap(() => {
          this.toastr.success('Buy Box deleted successfully');
        })
      ),
    { dispatch: false }
  );
}
