<div class="welcome-user-modal-wrapper">
  <p>
    You officially have access to the Rebuilt Marketplace, now let’s find your
    next deal.
  </p>
  <div class="buttons">
    <app-shared-button (click)="closeAuthModal()"
      >View marketplace</app-shared-button
    >
  </div>
</div>
