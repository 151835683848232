import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { OPTIONS_MARKETS } from '@core/constants/multi-selectors';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { setupAccountAction } from '@state/auth/auth.actions';
import { selectAuthLoading } from '@state/auth/auth.selectors';

@UntilDestroy()
@Component({
  selector: 'app-account-setup-modal-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class AccountSetupFormComponent implements OnInit {
  protected loading = false;
  protected focused = '';
  public accountSetupForm: FormGroup = this.formBuilder.group({
    roles: new FormControl('', [Validators.required]),
    markets: new FormControl('', [Validators.required]),
    investmentStyles: new FormControl('', []),
    rehabComfortabilities: new FormControl('', []),
  });

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {}

  protected options = {
    roles: [
      { key: 'LICENSED_AGENT', value: 'Licensed agent' },
      { key: 'INVESTOR', value: 'Investor' },
      { key: 'WHOLESALER', value: 'Wholesaler' },
      { key: 'LENDER', value: 'Lender' },
      { key: 'GENERAL_CONTRACTOR', value: 'General contractor' },
      { key: 'SUB_CONTRACTOR', value: 'Sub-contractor' },
    ],
    investmentStyles: [
      { key: 'FIX_AND_FLIP', value: 'Fix and flip' },
      { key: 'BUY_AND_HOLD', value: 'Buy and hold' },
      { key: 'NEW_BUILD', value: 'New build' },
    ],
    rehabComfortabilities: [
      { key: 'TURN_KEY', value: 'Turn key' },
      { key: 'COSMETIC', value: 'Cosmetic' },
      { key: 'SIGNIFICANT', value: 'Significant' },
      { key: 'GUT_REHAB', value: 'Gut rehab' },
    ],
    markets: OPTIONS_MARKETS,
  };

  ngOnInit(): void {
    this.store
      .select(selectAuthLoading)
      .pipe(untilDestroyed(this))
      .subscribe((loading) => {
        this.loading = loading;
        if (loading) {
          this.accountSetupForm.disable();
        } else {
          this.accountSetupForm.enable();
        }
      });
  }

  submitHandler() {
    if (this.accountSetupForm.invalid) {
      this.accountSetupForm.markAllAsTouched();
      return;
    }
    const values = this.accountSetupForm.value;
    if (typeof values.investmentStyles === 'string') {
      values.investmentStyles = [];
    }
    if (typeof values.rehabComfortabilities === 'string') {
      values.rehabComfortabilities = [];
    }
    this.store.dispatch(setupAccountAction({ input: values }));
  }

  handleRoles(values: string[]) {
    this.accountSetupForm.controls['roles'].setValue(values);
  }

  handleMarkets(values: string[]) {
    this.accountSetupForm.controls['markets'].setValue(values);
  }

  handleInvestmentStyles(values: string[]) {
    this.accountSetupForm.controls['investmentStyles'].setValue(values);
  }

  handleRehabComfortabilities(values: string[]) {
    this.accountSetupForm.controls['rehabComfortabilities'].setValue(values);
  }
}
