import { gql, TypedDocumentNode } from 'apollo-angular';

import { IUserPreferenceData, IUserPreferenceInput } from '@shared/models/user';

import { MUTATION_ERROR_FRAGMENT } from '../errors.fragments';

export const CREATE_USER_PREFERENCE: TypedDocumentNode<
  IUserPreferenceData,
  IUserPreferenceInput
> = gql`
  ${MUTATION_ERROR_FRAGMENT}
  mutation createUserPreference($input: CreateUserPreferenceInput!) {
    createUserPreference(input: $input) {
      result {
        roles
        markets
        investmentStyles
        rehabComfortabilities
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;
