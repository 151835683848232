import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from 'apollo-angular/http';
import * as Sentry from '@sentry/angular-ivy';

import { environment } from '../environments/environment';

const uri = environment.api + '/gql';

export function createApollo(httpLink: HttpLink): ApolloClientOptions<{}> {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const authStorage = localStorage.getItem('auth');
    const auth = authStorage ? JSON.parse(authStorage) : null;
    const userData = auth?.user;
    const user = userData
      ? {
          id: userData?.id || '-',
          email: userData?.email || '-',
          surname: userData?.surname || '-',
        }
      : undefined;

    if (graphQLErrors) {
      graphQLErrors.forEach((err) => {
        const { message, locations, path } = err;
        const formattedError = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
        Sentry.captureMessage(formattedError, { user });
      });
    }

    if (networkError) {
      Sentry.captureException(networkError, { user });
      console.log(`[Network error]:`, networkError);
    }
  });

  const link = ApolloLink.from([errorLink, httpLink.create({ uri })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache,
    defaultOptions: {
      query: { fetchPolicy: 'no-cache' },
      mutate: { fetchPolicy: 'no-cache' },
    },
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
