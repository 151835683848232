import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  OnDestroy,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { translateInputError } from '@core/utils/errors';
import { TIconName } from '@shared/models/icons';

@Component({
  selector: 'app-shared-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements OnChanges, OnDestroy {
  @Input() for: string = '';
  @Input() label: string = '';

  @Input() selector?: boolean = false;
  @Input() theme: 'white' | 'dark' = 'white';
  @Input() prefix?: string;
  @Input() icon?: TIconName;

  @Input() value?: string | number = '';
  @Input() focused: boolean = false;
  @Input() disabled: boolean = false;

  @Input() touched?: boolean = false;
  @Input() errors?: ValidationErrors | null;
  @Input() patternError?: string;

  @Input() type?: 'password';
  @Output() toggleVisibility = new EventEmitter<'text' | 'password'>();

  public passwordVisibility: 'text' | 'password' = 'password';
  public errorMessage?: string;

  public iconName?: TIconName;
  public iconSize = 18;

  ngOnChanges(): void {
    if (this.touched && this.errors) {
      const firstError = Object.values(this.errors)[0];
      this.errorMessage =
        typeof firstError === 'string'
          ? firstError
          : translateInputError(this.errors, this.patternError);
    }
    this.setIcon();
  }

  setIcon(): void {
    if (this.type === 'password') {
      this.iconName = 'eye';
      this.iconSize = 20;
    } else if (this.selector) {
      this.iconName = 'chevron-down-2';
      this.iconSize = 18;
    } else if (this.icon) {
      this.iconName = this.icon;
      this.iconSize = 18;
    } else {
      this.iconName = undefined;
    }
  }

  hangleToggleVisibility(): void {
    this.passwordVisibility =
      this.passwordVisibility === 'text' ? 'password' : 'text';
    this.toggleVisibility.emit(this.passwordVisibility);
  }

  ngOnDestroy(): void {
    this.toggleVisibility.complete();
  }
}
