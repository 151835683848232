import { gql } from 'apollo-angular';

export const MUTATION_ERROR_FRAGMENT = gql`
  fragment ErrorFragment on MutationError {
    code
    fields
    message
    shortMessage
    vars
  }
`;
