import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import {
  clearRequestPasswordResetAction,
  setAuthModal,
} from '@state/auth/auth.actions';

@Component({
  selector: 'app-reset-password-sent',
  templateUrl: './reset-password-sent.component.html',
  styleUrls: ['./reset-password-sent.component.scss'],
})
export class ResetPasswordSentComponent implements OnInit {
  constructor(public store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(clearRequestPasswordResetAction());
  }

  openSignIn() {
    this.store.dispatch(setAuthModal({ authModal: 'signin' }));
  }
}
