import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { StringPipe } from '@shared/pipes';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { updateOpenModalAction } from '@state/config/config.actions';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shared-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnChanges, OnDestroy {
  @Input() open?: boolean;
  @Input() name?: string;
  @Input() slug?: string;
  @Input() sup?: string | null;
  @Input() size: 'small' | 'medium' = 'medium';
  @Output() modalClose = new EventEmitter<void>();

  constructor(private store: Store<AppState>) {}

  ngOnChanges(): void {
    this.store.dispatch(
      updateOpenModalAction({
        openModal: this.open
          ? this.slug ||
            new StringPipe().transform(this.name || 'any-modal', 'slugify')
          : undefined,
      })
    );
  }

  ngOnDestroy(): void {
    this.store.dispatch(updateOpenModalAction({ openModal: undefined }));
  }

  onCloseHandler(): void {
    this.modalClose.emit();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.open) return;
    const target = event.target as HTMLElement;
    if (target.classList.contains('wrapper-base-modal')) {
      this.onCloseHandler();
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (!this.open) return;
    event.preventDefault();
    this.onCloseHandler();
  }
}
