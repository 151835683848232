import { IGraphQLErrors } from '@shared/models/graphql';
import { InputErrorType } from '@shared/models/errors';
import { ValidationErrors } from '@angular/forms';

export const validateGraphQLErrors = (errors: IGraphQLErrors | Error) => {
  if (Array.isArray(errors)) {
    return errors.every((error) => {
      if (error.code && error.shortMessage) {
        return true;
      }
      return false;
    });
  }

  return false;
};

export const translateInputError = (
  errors: ValidationErrors,
  patternError?: string
): string => {
  const key: InputErrorType | string = Object.keys(errors)[0];
  const value = Object.values(errors)[0];

  if (key === 'required') {
    return 'This field is required';
  } else if (key === 'minlength') {
    return `This field must be at least ${value.requiredLength} characters`;
  } else if (key === 'maxlength') {
    return `This field must be at most ${value.requiredLength} characters`;
  } else if (key === 'email') {
    return `This field must be a valid email`;
  } else if (key === 'matchValues') {
    return 'Value does not match';
  } else if (key === 'custom') {
    const str = value as string;
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else if (key === 'pattern' && patternError) {
    return patternError;
  } else if (key === 'server') {
    const str = value as string;
    if (str.includes('%{regex}')) {
      return 'This field is invalid';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return 'This field is invalid';
  }
};
