import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import {
  clearRequestPasswordResetAction,
  requestPasswordResetAction,
} from '@state/auth/auth.actions';
import { selectAuthRequestPasswordReset } from '@state/auth/auth.selectors';

@UntilDestroy()
@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class ForgotPasswordFormComponent implements OnInit {
  protected loading = false;
  protected focused = false;
  public passwordForm: FormGroup = this.formBuilder.group({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.store.dispatch(clearRequestPasswordResetAction());
    this.store
      .select(selectAuthRequestPasswordReset)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.loading = !!data?.loading;
        if (data?.loading) {
          this.passwordForm.disable();
        } else {
          this.passwordForm.enable();
        }
      });
  }

  submitHandler() {
    if (this.passwordForm.invalid) {
      this.passwordForm.markAllAsTouched();
      return;
    }

    try {
      const values = this.passwordForm.value;
      this.store.dispatch(requestPasswordResetAction({ email: values.email }));
    } catch (err) {
      throw err;
    }
  }
}
