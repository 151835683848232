import { createAction, props } from '@ngrx/store';

import {
  IBuyBox,
  ICreateUserBuyBoxInput,
  IUpdateBuyBoxInput,
  IPageOfBuyBoxes,
  IPageOfBuyBoxesInput,
} from '@shared/models/buy-boxes';

export const setBuyBoxesLoadingAction = createAction(
  '[BuyBoxes] Set BuyBoxes Loading',
  props<{ loading: boolean }>()
);

export const listOwnBuyBoxesAction = createAction(
  '[BuyBoxes] Fetch BuyBoxes',
  props<IPageOfBuyBoxesInput>()
);

export const listOwnBuyBoxesActionSuccess = createAction(
  '[BuyBoxes] Fetch BuyBoxes Success',
  props<IPageOfBuyBoxes>()
);

export const listOwnBuyBoxesActionFailure = createAction(
  '[BuyBoxes] Fetch BuyBoxes Failure'
);

export const fetchBuyBoxAction = createAction(
  '[BuyBoxes] Fetch BuyBox',
  props<{ id: string }>()
);

export const fetchBuyBoxActionSuccess = createAction(
  '[BuyBoxes] Fetch BuyBox Success',
  props<IBuyBox>()
);

export const fetchBuyBoxActionFailure = createAction(
  '[BuyBoxes] Fetch BuyBox Failure'
);

export const createBuyBoxAction = createAction(
  '[BuyBoxes] Create BuyBox',
  props<ICreateUserBuyBoxInput>()
);

export const createBuyBoxActionSuccess = createAction(
  '[BuyBoxes] Create BuyBox Success',
  props<IBuyBox>()
);

export const createBuyBoxActionFailure = createAction(
  '[BuyBoxes] Create BuyBox Failure'
);

export const editBuyBoxAction = createAction(
  '[BuyBoxes] Edit BuyBox',
  props<IUpdateBuyBoxInput>()
);

export const editBuyBoxActionSuccess = createAction(
  '[BuyBoxes] Edit BuyBox Success',
  props<IBuyBox>()
);

export const editBuyBoxActionFailure = createAction(
  '[BuyBoxes] Edit BuyBox Failure'
);

export const deleteBuyBoxAction = createAction(
  '[BuyBoxes] Delete BuyBox',
  props<{ id: string }>()
);

export const deleteBuyBoxActionSuccess = createAction(
  '[BuyBoxes] Delete BuyBox Success',
  props<{ id: string }>()
);

export const deleteBuyBoxActionFailure = createAction(
  '[BuyBoxes] Delete BuyBox Failure'
);
