import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, take } from 'rxjs';

import { FavoritesService } from '@core/services/favorites.service';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import {
  fetchPropertiesActionSuccess,
  fetchPropertyActionSuccess,
} from '@state/properties/properties.actions';
import {
  selectProperties,
  selectPropertyItem,
} from '@state/properties/properties.selectors';

import {
  fetchFavoritesAction,
  fetchFavoritesActionSuccess,
  fetchFavoritesActionFailure,
  setPropertyAsFavoriteAction,
  setPropertyAsFavoriteActionSuccess,
  setPropertyAsFavoriteActionFinish,
  removePropertyFromFavoritesAction,
  removePropertyFromFavoritesActionSuccess,
  removePropertyFromFavoritesActionFinish,
} from './favorites.actions';

@Injectable()
export class FavoritesEffects {
  constructor(
    private actions$: Actions,
    private favoritesService: FavoritesService,
    private store: Store<AppState>
  ) {}

  fetchFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFavoritesAction),
      switchMap((query) =>
        this.favoritesService.fetchFavorites(query).pipe(
          map((res) => {
            if (res?.listFavorites) {
              return fetchFavoritesActionSuccess(res.listFavorites);
            } else {
              throw Error('No favorites found');
            }
          }),
          catchError((error) => {
            return [fetchFavoritesActionFailure()];
          })
        )
      )
    )
  );

  setPropertyAsFavorite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPropertyAsFavoriteAction),
      switchMap((action) =>
        this.favoritesService.setAsFavorite({ id: action.propertyId }).pipe(
          map((res) => {
            if (res?.setAsFavorite.result) {
              // update all properties in store
              this.store
                .select(selectProperties)
                .pipe(take(1))
                .subscribe((data) => {
                  const { pageOfProperties } = data;
                  if (pageOfProperties) {
                    this.store.dispatch(
                      fetchPropertiesActionSuccess({
                        ...pageOfProperties,
                        results: pageOfProperties.results.map((property) => {
                          if (property.id === action.propertyId) {
                            return {
                              ...property,
                              isFavorite: true,
                            };
                          }
                          return property;
                        }),
                      })
                    );
                  }
                });
              // update current property in store if the same
              this.store
                .select(selectPropertyItem)
                .pipe(take(1))
                .subscribe((data) => {
                  if (data?.property?.id === action.propertyId) {
                    this.store.dispatch(
                      fetchPropertyActionSuccess({
                        property: {
                          ...data.property,
                          isFavorite: true,
                        },
                      })
                    );
                  }
                });
              return setPropertyAsFavoriteActionSuccess({
                property: res.setAsFavorite.result,
              });
            } else {
              throw Error('Error setting property as favorite');
            }
          }),
          catchError((error) => {
            return [setPropertyAsFavoriteActionFinish()];
          })
        )
      )
    )
  );

  removePropertyFromFavorites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removePropertyFromFavoritesAction),
      switchMap((action) =>
        this.favoritesService
          .removeFromFavorite({ id: action.propertyId })
          .pipe(
            map((res) => {
              if (res?.removeFromFavorite.result) {
                // update all properties in store
                this.store
                  .select(selectProperties)
                  .pipe(take(1))
                  .subscribe((data) => {
                    const { pageOfProperties } = data;
                    if (pageOfProperties) {
                      this.store.dispatch(
                        fetchPropertiesActionSuccess({
                          ...pageOfProperties,
                          results: pageOfProperties.results.map((property) => {
                            if (property.id === action.propertyId) {
                              return {
                                ...property,
                                isFavorite: false,
                              };
                            }
                            return property;
                          }),
                        })
                      );
                    }
                  });
                // update current property in store if the same
                this.store
                  .select(selectPropertyItem)
                  .pipe(take(1))
                  .subscribe((data) => {
                    if (data?.property?.id === action.propertyId) {
                      this.store.dispatch(
                        fetchPropertyActionSuccess({
                          property: {
                            ...data.property,
                            isFavorite: false,
                          },
                        })
                      );
                    }
                  });
                return removePropertyFromFavoritesActionSuccess({
                  property: res.removeFromFavorite.result,
                });
              } else {
                throw Error('Error removing property from favorites');
              }
            }),
            catchError((error) => {
              return [removePropertyFromFavoritesActionFinish()];
            })
          )
      )
    )
  );
}
