import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
} from '@angular/core';
import { TIconName } from '@shared/models/icons';

@Component({
  selector: 'app-shared-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  @Input() size: number = 20;
  @Input() name?: TIconName;

  public iconPath: string = '';

  ngOnInit(): void {
    if (this.name) {
      this.iconPath = `assets/icons/${this.name}.svg`;
    }
  }
}
