import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';

import { SharedModule } from '@shared/shared.module';

import { FullLayoutComponent } from './full-layout.component';

@NgModule({
  declarations: [FullLayoutComponent],
  imports: [CommonModule, SharedModule, MatMenuModule, RouterModule],
  exports: [],
})
export class FullLayoutModule {}
