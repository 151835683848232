import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import {
  provideTippyConfig,
  tooltipVariation,
  popperVariation,
} from '@ngneat/helipopper';

import { LayoutModule } from '@shared/layout/layout.module';
import { FullLayoutModule } from '@shared/full-layout/full-layout.module';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers, metaReducers } from '@state/app.reducers';
import { ConfigEffects } from '@state/config/config.effects';
import { AuthEffects } from '@state/auth/auth.effects';
import { PropertiesEffects } from '@state/properties/properties.effects';
import { OffersEffects } from '@state/offers/offers.effects';
import { FavoritesEffects } from '@state/favorites/favorites.effects';
import { BuyBoxesEffects } from '@state/buy-boxes/buy-boxes.effects';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptor } from './core/services/auth.interceptor';
import { GlobalErrorHandler } from './core/services/global-error-handler';

import { GraphQLModule } from './graphql.module';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      name:
        'Rebuilt Marketplace' +
        (environment.production ? '' : ' - ' + environment.name),
      logOnly: environment.production,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    EffectsModule.forRoot([
      ConfigEffects,
      AuthEffects,
      PropertiesEffects,
      OffersEffects,
      FavoritesEffects,
      BuyBoxesEffects,
    ]),
    ToastrModule.forRoot(),
    GraphQLModule,
    LayoutModule,
    FullLayoutModule,
  ],
  providers: [
    provideEnvironmentNgxMask(),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: { ...tooltipVariation, arrow: true },
        popper: popperVariation,
      },
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
