import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuthModal } from '@shared/models/auth';
import { IUser } from '@shared/models/user';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';

@UntilDestroy()
@Component({
  selector: 'app-auth-modal-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AuthModalHeaderComponent implements OnInit {
  @Input() slug?: AuthModal = 'signin';

  protected user?: IUser;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select((state) => state.auth.user)
      .pipe(untilDestroyed(this))
      .subscribe((user) => (this.user = user));
  }
}
