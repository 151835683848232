import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, tap } from 'rxjs';
import { Router } from '@angular/router';

interface GraphQLRequestBody {
  operationName?: string;
  query: string;
  variables?: Record<string, any>;
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<GraphQLRequestBody>, next: HttpHandler) {
    const authStorage = localStorage.getItem('auth');
    const token = authStorage ? JSON.parse(authStorage).token : null;
    const user = authStorage ? JSON.parse(authStorage).user : null;

    const operationName = request.body?.operationName;
    const guestOperations = ['listValidProperties', 'getValidProperty'];

    if (
      (token && user?.confirmedAt) ||
      (token && operationName && !guestOperations.includes(operationName))
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap((event: any) => {
        if (event?.body?.errors && Array.isArray(event.body.errors)) {
          const errors = event.body.errors;
          const unauthenticated = errors.find(
            (error: any) => error?.message === 'unauthenticated'
          );
          if (unauthenticated) {
            this.router.navigate(['/logout']);
            throw errors;
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        throw error;
      })
    );
  }
}
