import { gql, TypedDocumentNode } from 'apollo-angular';

import { IPageOfFavoritesData } from '@shared/models/favorites';

import { PROPERTY_LIST_FRAGMENT } from './properties.queries';

export const FETCH_FAVORITES: TypedDocumentNode<IPageOfFavoritesData> = gql`
  ${PROPERTY_LIST_FRAGMENT}
  query listFavorites(
    $filter: PropertyFilterInput
    $limit: Int
    $offset: Int
    $sort: [PropertySortInput]
    $isLoggedIn: Boolean!
  ) {
    listFavorites(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      count
      results {
        ...PropertyListFragment
      }
    }
  }
`;
