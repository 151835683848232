<div
  *ngIf="name"
  class="wrapper"
  [ngStyle]="{ height: size + 'px', width: size + 'px' }"
>
  <img
    [src]="iconPath"
    [alt]="'icon ' + name"
    [width]="size"
    [height]="size"
    loading="lazy"
  />
</div>
