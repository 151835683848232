import { gql, TypedDocumentNode } from 'apollo-angular';

import { IFeatureFlagsRes } from '@shared/models/feature-flags';

export const GET_FEATURE_FLAGS: TypedDocumentNode<
  IFeatureFlagsRes,
  { keys: string[] }
> = gql`
  query getFeatureFlags($keys: [String]) {
    getFeatureFlags(keys: $keys) {
      featureFlags {
        isEnabled
        key
      }
    }
  }
`;
