import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs';

import { FeatureFlagsService } from '@core/services/feature-flags.service';

import {
  fetchFeatureFlagsAction,
  fetchFeatureFlagsFinishAction,
} from './config.actions';

@Injectable()
export class ConfigEffects {
  constructor(
    private actions$: Actions,
    private featureFlagsService: FeatureFlagsService
  ) {}

  fetchFeatureFlagsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchFeatureFlagsAction),
      switchMap((action) =>
        this.featureFlagsService.getFeatureFlags(action.keys).pipe(
          map((res) => {
            if (res.getFeatureFlags) {
              return fetchFeatureFlagsFinishAction(res.getFeatureFlags);
            } else {
              throw Error('No feature flags found');
            }
          }),
          catchError(() => {
            return [fetchFeatureFlagsFinishAction({ featureFlags: [] })];
          })
        )
      )
    )
  );
}
