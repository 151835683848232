import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Apollo } from 'apollo-angular';

import { FETCH_FAVORITES } from '@graphql/queries/favorites.queries';
import {
  SET_AS_FAVORITE,
  REMOVE_FROM_FAVORITE,
} from '@graphql/mutations/favorites.mutations';

import { IPageOfPropertyInput } from '@shared/models/properties';
import {
  IPageOfFavoritesData,
  ISetAsFavoriteInput,
  ISetAsFavoriteResult,
  IRemoveFromFavoriteInput,
  IRemoveFromFavoriteResult,
} from '@shared/models/favorites';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  constructor(private apollo: Apollo) {}

  fetchFavorites(
    query: IPageOfPropertyInput
  ): Observable<IPageOfFavoritesData> {
    return this.apollo
      .query({
        query: FETCH_FAVORITES,
        variables: { ...query, isLoggedIn: true },
      })
      .pipe(map((result) => result.data as IPageOfFavoritesData));
  }

  setAsFavorite(input: ISetAsFavoriteInput): Observable<ISetAsFavoriteResult> {
    return this.apollo
      .mutate({
        mutation: SET_AS_FAVORITE,
        variables: { ...input, isLoggedIn: true },
      })
      .pipe(map((result) => result.data as ISetAsFavoriteResult));
  }

  removeFromFavorite(
    input: IRemoveFromFavoriteInput
  ): Observable<IRemoveFromFavoriteResult> {
    return this.apollo
      .mutate({
        mutation: REMOVE_FROM_FAVORITE,
        variables: { ...input, isLoggedIn: true },
      })
      .pipe(map((result) => result.data as IRemoveFromFavoriteResult));
  }
}
