import { createReducer, on } from '@ngrx/store';

import { IFeatureFlag } from '@shared/models/feature-flags';

import {
  resetConfigAction,
  updateOpenModalAction,
  toggleHomeMapViewAction,
  fetchFeatureFlagsAction,
  fetchFeatureFlagsFinishAction,
  hideNoticeAction,
} from './config.actions';

export interface ConfigState {
  openModal?: string;
  mapView: boolean;
  featureFlags: {
    fetching: string[];
    items: IFeatureFlag[];
  };
  hiddenNotices: string[];
}

export const initialState: ConfigState = {
  openModal: undefined,
  mapView: true,
  featureFlags: {
    fetching: [],
    items: [],
  },
  hiddenNotices: [],
};

export const configReducer = createReducer(
  initialState,
  on(resetConfigAction, () => ({ ...initialState })),
  on(updateOpenModalAction, (state, action) => ({
    ...state,
    openModal: action.openModal,
  })),
  on(toggleHomeMapViewAction, (state) => ({
    ...state,
    mapView: !state.mapView,
  })),
  on(fetchFeatureFlagsAction, (state, action) => ({
    ...state,
    featureFlags: {
      ...state.featureFlags,
      fetching: [...action.keys],
    },
  })),
  on(fetchFeatureFlagsFinishAction, (state, action) => ({
    ...state,
    featureFlags: {
      ...state.featureFlags,
      fetching: [],
      items: [...action.featureFlags],
    },
  })),
  on(hideNoticeAction, (state, action) => ({
    ...state,
    hiddenNotices: [...state.hiddenNotices, action.notice],
  }))
);
