import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FeatureFlagsService } from '@core/services/feature-flags.service';

import { Store } from '@ngrx/store';
import { IUser } from '@shared/models/user';
import { AppState } from '@state/app.state';
import { selectAuthUser } from '@state/auth/auth.selectors';
import { setAuthModal } from '@state/auth/auth.actions';

@UntilDestroy()
@Component({
  selector: 'app-topbar-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  protected user?: IUser;
  protected homeScreen = false;

  protected favoritesFeatureFlag$ = this.featureFlags.isEnabled(
    'fe_favorite_property'
  );

  protected buyBoxesFeatureFlag$ = this.featureFlags.isEnabled('fe_buy_boxes');

  constructor(
    private store: Store<AppState>,
    private featureFlags: FeatureFlagsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectAuthUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this.user = user;
      });

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (
        ((this.router.url === '/' ||
          this.router.url.startsWith('/page/') ||
          this.router.url.startsWith('/?')) &&
          !this.router.url.startsWith('/maintenance')) ||
        this.router.url.startsWith('/auth/password-reset')
      ) {
        this.homeScreen = true;
      } else {
        this.homeScreen = false;
      }
    });
  }

  showSignInModal() {
    this.store.dispatch(setAuthModal({ authModal: 'signup' }));
  }
}
