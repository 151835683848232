import { Component, EventEmitter, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectAuthUser } from '@state/auth/auth.selectors';

@Component({
  selector: 'app-verify-identity-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
})
export class VerifyIdentityPhoneNumberComponent {
  @Output() toggleForm = new EventEmitter<boolean>();

  protected open = false;
  protected user$ = this.store.select(selectAuthUser);

  constructor(private store: Store<AppState>) {}

  toggleOpen(value: boolean): void {
    this.open = value;
    this.toggleForm.emit(!value);
  }
}
