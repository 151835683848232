import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  passwordValidator,
  compareValidator,
} from '@core/constants/validators';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { resetPasswordAction } from '@state/auth/auth.actions';
import { selectAuthResetPassword } from '@state/auth/auth.selectors';

@UntilDestroy()
@Component({
  selector: 'app-reset-password-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class ResetPasswordFormComponent implements OnInit {
  @Input({ required: true }) email: string = '';
  @Input({ required: true }) token?: string;
  protected loading = false;
  protected focused = '';
  public resetPasswordForm: FormGroup = this.formBuilder.group({
    password: new FormControl('', passwordValidator),
    passwordConfirmation: new FormControl('', passwordValidator),
  });

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {
    this.resetPasswordForm
      .get('passwordConfirmation')
      ?.setValidators([
        ...passwordValidator,
        compareValidator(
          this.resetPasswordForm.get('password'),
          this.resetPasswordForm.get('passwordConfirmation')
        ),
      ]);
  }

  ngOnInit(): void {
    this.store
      .select(selectAuthResetPassword)
      .pipe(untilDestroyed(this))
      .subscribe((state) => {
        this.loading = !!state?.loading;
      });
  }

  submitHandler() {
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }
    try {
      const values = this.resetPasswordForm.value;
      this.store.dispatch(
        resetPasswordAction({
          email: this.email,
          input: {
            ...values,
            resetToken: this.token,
          },
        })
      );
    } catch (err) {
      throw err;
    }
  }

  focusHandler(event: FocusEvent) {
    const name = (event.target as HTMLInputElement).name;
    this.focused = name;
    this.resetPasswordForm.controls[name].markAsTouched();
  }

  blurHandler(event: FocusEvent) {
    const name = (event.target as HTMLInputElement).name;
    if (this.focused === name) this.focused = '';
  }
}
