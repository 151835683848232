import { gql, TypedDocumentNode } from 'apollo-angular';

import {
  ICreateUserBuyBoxInput,
  ICreateUserBuyBoxResult,
  IDeleteUserBuyBoxResult,
  IUpdateBuyBoxInput,
  IUpdateUserBuyBoxResult,
} from '@shared/models/buy-boxes';

import { BUY_BOX_FRAGMENT } from '@graphql/queries/buy-boxes.queries';
import { MUTATION_ERROR_FRAGMENT } from '@graphql/errors.fragments';

export const CREATE_USER_BUY_BOX: TypedDocumentNode<
  ICreateUserBuyBoxResult,
  ICreateUserBuyBoxInput
> = gql`
  ${BUY_BOX_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
  mutation createUserBuyBox($input: CreateUserBuyBoxInput!) {
    createUserBuyBox(input: $input) {
      result {
        ...BuyBoxFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;

export const UPDATE_USER_BUY_BOX: TypedDocumentNode<
  IUpdateUserBuyBoxResult,
  IUpdateBuyBoxInput
> = gql`
  ${BUY_BOX_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
  mutation updateUserBuyBox($id: ID!, $input: UpdateUserBuyBoxInput!) {
    updateUserBuyBox(id: $id, input: $input) {
      result {
        ...BuyBoxFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;

export const DELETE_USER_BOX_BUY_BOX: TypedDocumentNode<
  IDeleteUserBuyBoxResult,
  { id: string }
> = gql`
  ${MUTATION_ERROR_FRAGMENT}
  mutation deleteUserBuyBox($id: ID!) {
    deleteUserBuyBox(id: $id) {
      errors {
        ...ErrorFragment
      }
    }
  }
`;
