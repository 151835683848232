import { Validators, AbstractControl, ValidatorFn } from '@angular/forms';

export const passwordValidator = [Validators.required, Validators.minLength(8)];

export const compareValidator = (
  controlOne: AbstractControl | null,
  controlTwo: AbstractControl | null,
  message?: string | null,
  replace?: RegExp | null
): ValidatorFn => {
  return () => {
    if (replace) {
      if (
        controlOne?.value?.replace(replace, '') !==
        controlTwo?.value?.replace(replace, '')
      ) {
        return { custom: message || 'Passwords do not match' };
      }
      return null;
    }
    if (controlOne?.value !== controlTwo?.value) {
      return { custom: message || 'Passwords do not match' };
    }
    return null;
  };
};
