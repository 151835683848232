import { Component } from '@angular/core';

import { menuLinks, socialLinks } from '@core/constants';
import { ILink, ISocialLink } from '@shared/models/links';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectAuthToken } from '@state/auth/auth.selectors';

@Component({
  selector: 'app-shared-layout-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  protected menuLinks = menuLinks;
  protected socialLinks = socialLinks;
  protected year: number = new Date().getFullYear();
  protected isAuthenticated = this.store.select(selectAuthToken);

  constructor(private store: Store<AppState>) {}

  trackByFn(index: number, item: ILink | ISocialLink): string {
    return item.title;
  }
}
