<app-shared-container>
  <div class="wrapper">
    <h3>About the Rebuilt Companies</h3>
    <p>
      Thank you for your interest in Rebuilt (Rebuilt Realty and its affiliated
      companies listed below are collectively the “Rebuilt Realty”). The goal of
      this page is to provide details on our family of companies and the
      different ways you may interact with them, as well as disclosures that may
      be relevant to you.
    </p>
    <p>
      The Rebuilt Companies shall disclose any and all affiliated business
      arrangements between the parties to sellers and buyers of real property at
      the outset of any real estate transaction.
    </p>

    <h3>Rebuilt Realty</h3>
    <p>
      Rebuilt Realty, a Tennessee corporation, is the owner, host, manager and
      provider of the website,
      <a href="https://www.rebuilt.com/" target="_blank"
        >https://www.rebuilt.com/</a
      >, as well as any subdomains thereof, including but not limited to,
      <a href="https://marketplace.rebuilt.com/" target="_blank"
        >https://marketplace.rebuilt.com/</a
      >
      (collectively, the “Site”). The Site is an online platform, also referred
      to as the “Rebuilt Marketplace”, which enables Site users to research,
      discover, apply for, communicate regarding, and (if approved) obtain real
      estate transaction products or services, and related products and services
      (offered and/or provided by a wholly owned subsidiary, Rebuilt Realty,
      doing business as Rebuilt, a Tennessee corporation, which holds a real
      estate brokerage license in multiple states as stated herein – as
      described further below).
    </p>

    <h3>Rebuilt Realty</h3>
    <p>
      Any and all real estate brokerage services advertised, offered, performed
      or completed, through the Rebuilt Marketplace, or outside the scope
      thereof, are solely advertised, offered, performed, or completed by
      Rebuilt Realty, doing business as Rebuilt (“Rebuilt Realty”). Rebuilt
      Realty is a wholly owned subsidiary of Rebuilt Realty
    </p>
    <p>
      Rebuilt Realty holds real estate brokerage licenses in the following
      states:
    </p>

    <div class="broker">
      <div class="card">
        <div class="header">
          <div class="txt-light">Broker</div>
          <div class="title">Sherry Daminski</div>
        </div>
        <div class="content">
          <span class="txt-light">Firm:</span>
          <span>Rebuilt Brookerage LLC</span>
          <span class="txt-light">Real Estate ID:</span>
          <span>369076</span>
        </div>

        <address>
          <a href="mailto:sherry.daminski@rebuilt.com">
            sherry.daminski@rebuilt.com
          </a>
          <a href="tel:+15407206964">(540) 720-6964 (mobile)</a>
          <a href="tel:+16156194678">(615) 619-4678 (office TN)</a>
          <span>307 15 Century Blvd</span>
          <span>Nashville, TN 37214</span>
        </address>
      </div>
    </div>

    <p>
      If any Site users of the Rebuilt Marketplace are residents of, or are
      looking to sell or buy real property in, a state where Rebuilt Realty is
      NOT currently licensed to perform real estate brokerage activities, please
      be advised that Rebuilt Realty is not authorized to offer or perform – and
      will not offer or perform – any real estate brokerage services. As such,
      the Rebuilt Marketplace, hosted by Rebuilt Realty, is merely an online
      platform, which may be utilized by other real estate brokers, licensed in
      such states, to facilitate the listing or sale of real property for
      sellers and buyers therein.
    </p>

    <h3>Rebuilt Companies</h3>
    <p>
      Rebuilt Companies may act as principals engaged in the business of buying
      and selling real property for investment purposes. In addition to Rebuilt
      Realty, the company engaged in these activities is Rebuilt Offers, LLC, of
      which Rebuilt Realty is the Manager (and of which Rebuilt Realty is the
      sole member of its General Partner, Rebuilt Offers, LLC). Rebuilt Offers,
      LLC also owns various property holding companies as part of these
      activities. We anticipate that in the future additional Rebuilt Companies
      or entities they control may engage in these same activities as well. We
      collectively refer to these companies as the “Rebuilt Companies”.
    </p>
    <p>
      Thus, Rebuilt Realty and/or the Rebuilt Companies may advertise and/or
      present offers, including cash offers, to sellers of real property. Such
      offers may occur for properties whether or not currently listed on the
      Rebuilt Marketplace. Rebuilt Realty and/or the Rebuilt Companies may
      repair or rehab, and/or resell any real properties purchased, and for a
      potential profit. These entities are NOT licensed to practice real estate
      and do not offer or perform any services requiring a real estate license
      on behalf of anyone, but are solely engaged as principals in connection
      with real estate purchase and sales activity. As a reminder – any and all
      services requiring a real estate license are performed by Rebuilt Realty.
      In addition, Rebuilt Realty may provide brokerage services in transactions
      involving Rebuilt Realty or the Rebuilt Companies, to the extent permitted
      by applicable law.
    </p>

    <h3>Rebuilt Marketplace</h3>
    <p>
      Sellers who work with Rebuilt Realty and list their properties on the
      Rebuilt Marketplace grant permission to Rebuilt to require that all offers
      submitted by buyers through the Rebuilt Marketplace, and presented by
      Rebuilt Realty to sellers, shall include terms and conditions in
      accordance with the following requirements: (i) The sale of the Property
      shall be sold “as is” and not subject to any repair requests; (ii) Buyer’s
      offer to purchase the Property shall be “all cash”; (iii) The sale shall
      not include or be subject to any contingencies, including but not limited
      to, contingencies related to inspections, appraisals or loans; and (iv)
      The sale shall not include or be subject to any in-person showings or
      property inspections.
    </p>
    <p>
      Notwithstanding the foregoing, in the interest of presenting all offers
      received by Rebuilt Realty to sellers, some buyers may submit offers which
      may include different terms than specified above. Sellers are advised that
      all terms and conditions are ultimately negotiable between sellers and
      buyers, and that sellers are not required to accept any offers submitted
      by buyers on the Rebuilt Marketplace.
    </p>
    <p>
      Rebuilt Realty assists multiple sellers and buyers in connection with the
      sale and purchase of real property through the Rebuilt Marketplace, and/or
      outside the scope thereof. Any and all agency relationships shall be
      disclosed to sellers and buyers as required by state law.
    </p>
    <p>
      As a condition of working with Rebuilt Realty and using the Rebuilt
      Marketplace to facilitate the listing and sale of real properties, Rebuilt
      Realty will request that buyers are responsible for the payment of Rebuilt
      Realty’s real estate compensation at the closing of the transaction.
    </p>
    <p>
      In connection therewith, Rebuilt Realty reserves the right to charge
      different fees for its licensed activities (e.g., premiums, commissions),
      including discounted fees (or rebates), to different buyers (including,
      but not limited to, Rebuilt Companies or other affiliated buying entities,
      as defined herein, who may be competing with other buyers, who may be
      represented by Rebuilt Realty, to purchase real properties listed on the
      Rebuilt Marketplace, or outside the scope thereof), and/or different
      sellers (including, but not limited to, Rebuilt Companies or other
      affiliated selling entities, as defined herein, who may be selling
      properties listed on the Rebuilt Marketplace along with the properties of
      other sellers, who may be represented by Rebuilt Realty, or outside the
      scope thereof).
    </p>
    <p>
      Notwithstanding the foregoing, the amount or rate of real estate
      commissions is not fixed by law. They are set by each broker individually
      and may be negotiable between the seller and broker, or between the buyer
      and broker. Furthermore, sellers of real property listed on the Rebuilt
      Marketplace are advised that some offers submitted by buyers and presented
      by Rebuilt Realty to sellers, may include other proposed compensation
      terms, including but not limited to, requests for sellers to pay all, or a
      portion of, Rebuilt Realty’s compensation, and/or any other compensation
      that may be due to other real estate brokerage(s). Sellers are not
      required to accept any offers submitted by buyers on the Rebuilt
      Marketplace.
    </p>

    <h3>Real Time Offer Feedback</h3>
    <p>
      Rebuilt Realty notifies all buyers who submit offers via the Rebuilt
      Marketplace on listed properties whether they are the “highest offer” or
      “not the highest offer.” Further, on an ongoing basis, and until an offer
      has been accepted by a seller, Rebuilt Realty also notifies the top two
      (2) highest offerors in connection with a listed property when either they
      are the highest offer or are no longer the highest offer.
    </p>

    <h3>No Legal, Tax, or Investment Advice</h3>
    <p>
      Rebuilt Companies do not provide legal, tax, or investment advice. Any
      published articles, blog posts, resources or other related material
      located on the Site is for informational purposes only. It has been
      prepared without regard to the individual financial circumstances and
      objectives of persons who receive it. Any references to other sources or
      sites is delivered “as is” and Rebuilt makes no representation or
      endorsement of any kind with respect to the accuracy of such information
      or its suitability for any particular use. Please seek the advice of an
      attorney, tax professional and/or financial advisor for guidance to
      properly evaluate particular investments and/or strategies.
    </p>
    <p>
      No warranties are made as to the accuracy of any descriptions of real
      property listings located on the Rebuilt Marketplace. Not all properties
      may be eligible for all services and/or products offered by Rebuilt
      Realty.
    </p>

    <h3>Lending Activities</h3>
    <p>
      Rebuilt Realty offers loans for business purposes only and not for
      personal, family or household use. Any lending or loan products offered
      are only applicable in California, Colorado, Georgia, Florida, Tennessee,
      Texas and Washington.
    </p>
    <p>
      Nothing represented in any marketing material distributed by Rebuilt
      Realty shall be considered a commitment to lend. All potential loans are
      subject to underwriting and due diligence until a definitive loan
      agreement is signed. The rates, costs, and fees advertised do not apply to
      all loans made or negotiated, and loans made or negotiated by Rebuilt
      Realty may have higher interest rates or additional costs and fees.
    </p>
    <p>
      Rebuilt Realty is a licensed finance lender with the California Department
      of Financial Protection and Innovation, CFL #60DBO-122336. Loans made or
      arranged pursuant to a California Financing Law license.
    </p>

    <h3>Questions</h3>
    <p>
      Should you have any questions about the roles and activities performed by
      any of the Rebuilt Companies, or the status of any relationship you may or
      may not have with them, please contact us at
      <a href="mailto:legal@rebuilt.com">legal@rebuilt.com</a>.
    </p>
  </div>
</app-shared-container>
