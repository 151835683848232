import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { fetchUserAction } from '@state/auth/auth.actions';
import { selectAuth } from '@state/auth/auth.selectors';

@Component({
  selector: 'app-deactivated',
  templateUrl: './deactivated.component.html',
  styleUrls: [
    '../sign-in/sign-in.component.scss',
    './deactivated.component.scss',
  ],
})
export class DeactivatedComponent implements OnInit {
  public loading = true;

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.store.dispatch(fetchUserAction());

    this.store.select(selectAuth).subscribe((auth) => {
      if (!auth?.token || !auth?.user) {
        this.router.navigate(['/auth', 'sign-in']);
        return;
      }
      if (auth?.user?.isActive !== false) {
        this.router.navigate(['/']);
        return;
      }
      this.loading = false;
    });
  }
}
