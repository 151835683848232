import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { setAuthModal } from '@state/auth/auth.actions';
import { selectAuthToken } from '@state/auth/auth.selectors';

@UntilDestroy()
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  public email: string | null = null;
  public token: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.store
      .select(selectAuthToken)
      .pipe(untilDestroyed(this))
      .subscribe((token) => {
        if (token) {
          this.store.dispatch(setAuthModal({ authModal: undefined }));
        }
      });

    this.route.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.token = params['token'];
      if (!this.email || !this.token) {
        this.store.dispatch(setAuthModal({ authModal: undefined }));
      }
    });
  }
}
