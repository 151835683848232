import { createAction, props } from '@ngrx/store';

import {
  IPageOfOffer,
  IPageOfOfferInput,
  IPlaceOfferInput,
} from '@shared/models/offers';

export const resetOffersAction = createAction('[Offers] Reset');

export const fetchOffersAction = createAction(
  '[Offers] Fetch Offers',
  props<IPageOfOfferInput>()
);

export const fetchOffersActionSuccess = createAction(
  '[Offers] Fetch Offers Success',
  props<IPageOfOffer>()
);

export const fetchOffersActionFailure = createAction(
  '[Offers] Fetch Offers Failure'
);

export const createOfferAction = createAction(
  '[Offers] Create Offer',
  props<IPlaceOfferInput>()
);

export const createOfferActionSuccess = createAction(
  '[Offers] Create Offer Success'
);

export const createOfferActionFailure = createAction(
  '[Offers] Create Offer Failure'
);

export const fetchPropertyOfferAction = createAction(
  '[Offers] Fetch Property Offer',
  props<{ propertyId: string }>()
);

export const fetchPropertyOfferActionFinished = createAction(
  '[Offers] Fetch Property Offer Success'
);

export const withdrawOfferAction = createAction(
  '[Offers] Withdraw Offer',
  props<{ id: string }>()
);

export const withdrawOfferActionFinished = createAction(
  '[Offers] Withdraw Offer Finished'
);

export const clearSuccessOfferSentAction = createAction(
  '[Offers] Clear Success Offer Sent'
);
