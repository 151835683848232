import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import {
  setLoadingAction,
  confirmPhoneNumberAction,
  sendPhoneNumberConfirmationAction,
  fetchUserAction,
} from '@state/auth/auth.actions';
import { selectAuth } from '@state/auth/auth.selectors';

@UntilDestroy()
@Component({
  selector: 'app-verify-identity-modal-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class VerifyIdentityFormComponent implements OnInit {
  protected loading = false;
  protected focused = false;
  public form: FormGroup = this.formBuilder.group({
    digit1: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    digit2: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    digit3: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    digit4: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    digit5: new FormControl('', [Validators.required, Validators.maxLength(1)]),
    digit6: new FormControl('', [Validators.required, Validators.maxLength(1)]),
  });

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.store.dispatch(fetchUserAction());

    this.store
      .select(selectAuth)
      .pipe(untilDestroyed(this))
      .subscribe((auth) => {
        this.loading = !!auth.loading;
        if (auth.loading) {
          this.form.disable();
        } else {
          this.form.enable();
        }
      });
  }

  sendNewCode() {
    try {
      this.store.dispatch(sendPhoneNumberConfirmationAction());
    } catch (err) {
      throw err;
    }
  }

  submitHandler() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const code = Object.values(this.form.value).join('');
    this.store.dispatch(setLoadingAction({ loading: true }));
    this.store.dispatch(confirmPhoneNumberAction({ input: { code } }));
  }

  onKeyDown(event: KeyboardEvent, index: number): void {
    const input = event.target as HTMLInputElement;
    const key = event.key;

    // Check if the key combination is for pasting (Ctrl + V or Cmd + V)
    const isPaste =
      (key === 'v' || key === 'V') && (event.ctrlKey || event.metaKey);

    // Allow only backspace, delete, arrow keys, numbers, and paste keys. Ignore all other key presses.
    if (
      !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(key) &&
      (key < '0' || key > '9') &&
      !isPaste
    ) {
      event.preventDefault();
      return;
    }

    // If the input already has one digit and the key pressed is a number (and not a paste action), prevent further input
    if (input.value.length === 1 && key >= '0' && key <= '9' && !isPaste) {
      event.preventDefault();
      return;
    }

    // Handle the backspace key when the current field is empty and it's not the first field
    if (key === 'Backspace' && input.value === '' && index > 0) {
      event.preventDefault();
      const previousInput: HTMLInputElement = document.querySelectorAll(
        '.verification-code-input'
      )[index - 1] as HTMLInputElement;
      this.form.get(`digit${index}`)?.setValue('');
      previousInput.focus();
    }
  }

  onKeyUp(event: KeyboardEvent, index: number): void {
    const input = event.target as HTMLInputElement;
    const key = event.key;

    // Navigate to the previous input when the left arrow key is pressed
    if (key === 'ArrowLeft' && index > 0) {
      const previousInput: HTMLInputElement = document.querySelectorAll(
        '.verification-code-input'
      )[index - 1] as HTMLInputElement;
      previousInput.focus();
    }
    // Navigate to the next input when the right arrow key is pressed
    else if (
      key === 'ArrowRight' &&
      index < Object.keys(this.form.controls).length - 1
    ) {
      const nextInput: HTMLInputElement = document.querySelectorAll(
        '.verification-code-input'
      )[index + 1] as HTMLInputElement;
      nextInput.focus();
    }
    // Auto-focus the next input when a number is entered
    else if (
      /^[0-9]$/.test(input.value) &&
      index < Object.keys(this.form.controls).length - 1
    ) {
      const nextInput: HTMLInputElement = document.querySelectorAll(
        '.verification-code-input'
      )[index + 1] as HTMLInputElement;
      nextInput.focus();
    }
  }

  onPaste(event: ClipboardEvent, index: number): void {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the pasted text from the clipboard
    const pastedText = event.clipboardData?.getData('text') || '';

    // Split the pasted text into individual characters
    const characters = pastedText.split('');

    // Get an array of control names
    const controlNames = Object.keys(this.form.controls);

    // Assign characters to the form controls, starting from the current index
    for (
      let i = 0;
      i < characters.length && index + i < controlNames.length;
      i++
    ) {
      const controlName = controlNames[index + i];
      this.form.get(controlName)?.setValue(characters[i]);
    }

    // Focus the next empty input field or the last one if all are filled
    const nextIndex = Math.min(
      index + characters.length,
      controlNames.length - 1
    );
    const nextInput: HTMLInputElement = document.querySelectorAll(
      '.verification-code-input'
    )[nextIndex] as HTMLInputElement;
    nextInput.focus();
  }
}
