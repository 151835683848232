import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { LightgalleryModule } from 'lightgallery/angular';
import { TippyDirective } from '@ngneat/helipopper';

import { InputComponent } from './components/input/input.component';
import { ButtonComponent } from './components/button/button.component';
import { IconComponent } from './components/icon/icon.component';
import { PropertyListComponent } from './components/property-list/property-list.component';
import { PropertyListInfoComponent } from './components/property-list/info/info.component';
import { PropertyListStatusComponent } from './components/property-list/status/status.component';
import { PropertyListLabelComponent } from './components/property-list/label/label.component';
import { PropertyShareCopyLinkComponent } from './components/property-share-modal/copy-link/copy-link.component';
import { PropertyShareButtonComponent } from './components/property-share-button/property-share-button.component';
import { PropertyShareModalComponent } from './components/property-share-modal/property-share-modal.component';
import { PropertyShareEmailsComponent } from './components/property-share-modal/share-emails/share-emails.component';
import { FavoritePropertyComponent } from './components/favorite-property/favorite-property.component';
import { ContainerComponent } from './components/container/container.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { ModalComponent } from './components/modal/modal.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { LoadingComponent } from './components/loading/loading.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { OfferModalComponent } from './components/offer-modal/offer-modal.component';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { ProfileLayoutComponent } from './components/profile-layout/profile-layout.component';
import { ProfileSidebarComponent } from './components/profile-layout/sidebar/sidebar.component';
import { MultiSelectorComponent } from './components/multi-selector/multi-selector.component';

import { TopbarComponent } from './components/topbar/topbar.component';
import { MenuMobileComponent } from './components/topbar/menu-mobile/menu-mobile.component';
import { UserComponent } from './components/topbar/user/user.component';
import { FooterComponent } from './components/footer/footer.component';

import { FiltersComponent } from './components/filters/filters.component';
import { FilterStatesComponent } from './components/filters/filter-states/filter-states.component';
import { FilterStatusComponent } from './components/filters/filter-status/filter-status.component';
import { FiltersFilterPriceComponent } from './components/filters/filter-price/filter-price.component';
import { FilterBedroomsComponent } from './components/filters/filter-bedrooms/filter-bedrooms.component';
import { FilterBathroomsComponent } from './components/filters/filter-bathrooms/filter-bathrooms.component';
import { FilterSubtypeComponent } from './components/filters/filter-subtype/filter-subtype.component';
import { FilterSquareFootComponent } from './components/filters/filter-square-foot/filter-square-foot.component';
import { FilterLotSizeComponent } from './components/filters/filter-lot-size/filter-lot-size.component';
import { FilterYearBuiltComponent } from './components/filters/filter-year-built/filter-year-built.component';
import { FilterCountComponent } from './components/filters/count/count.component';

import { FiltersV2Component } from './components/filters-v2/filters-v2.component';
import { FilterV2ButtonsComponent } from './components/filters-v2/filter-v2-buttons/filter-v2-buttons.component';
import { FilterV2MarketsComponent } from './components/filters-v2/filter-v2-markets/filter-v2-markets.component';
import { FilterV2StatusComponent } from './components/filters-v2/filter-v2-status/filter-v2-status.component';
import { FilterV2PriceComponent } from './components/filters-v2/filter-v2-price/filter-v2-price.component';
import { FilterV2SubtypeComponent } from './components/filters-v2/filter-v2-subtype/filter-v2-subtype.component';
import { FilterV2MoreComponent } from './components/filters-v2/filter-v2-more/filter-v2-more.component';
import { FilterV2MoreDesktopComponent } from './components/filters-v2/filter-v2-more-desktop/filter-v2-more-desktop.component';
import { FilterV2BedsComponent } from './components/filters-v2/filter-v2-beds/filter-v2-beds.component';
import { FilterV2BathsComponent } from './components/filters-v2/filter-v2-baths/filter-v2-baths.component';
import { FilterV2SqftComponent } from './components/filters-v2/filter-v2-sqft/filter-v2-sqft.component';
import { FilterV2LotSizeComponent } from './components/filters-v2/filter-v2-lot-size/filter-v2-lot-size.component';
import { FilterV2YearBuiltComponent } from './components/filters-v2/filter-v2-year-built/filter-v2-year-built.component';
import { FilterV2FinancialsComponent } from './components/filters-v2/filter-v2-financials/filter-v2-financials.component';
import { FilterV2ZipComponent } from './components/filters-v2/filter-v2-zip/filter-v2-zip.component';

import { HtmlFormatPipe, StatusPipe, StringPipe, PageUrlPipe } from './pipes';
import { PriceLabelPipe } from './components/filters/pipes/price-label.pipe';

import { AuthModalComponent } from './components/auth-modal/auth-modal.component';
import { AuthModalHeaderComponent } from './components/auth-modal/header/header.component';

import { SignInComponent } from './components/auth-modal/signin/signin.component';
import { SignInFormComponent } from './components/auth-modal/signin/form/form.component';
import { SignUpComponent } from './components/auth-modal/signup/signup.component';
import { SignUpFormComponent } from './components/auth-modal/signup/form/form.component';
import { VerifyIdentityComponent } from './components/auth-modal/verify-identity/verify-identity.component';
import { VerifyIdentityFormComponent } from './components/auth-modal/verify-identity/form/form.component';
import { VerifyIdentityPhoneNumberComponent } from './components/auth-modal/verify-identity/phone-number/phone-number.component';
import { VerifyIdentityUpdatePhoneComponent } from './components/auth-modal/verify-identity/update-phone/update-phone.component';
import { AccountSetupComponent } from './components/auth-modal/account-setup/account-setup.component';
import { AccountSetupFormComponent } from './components/auth-modal/account-setup/form/form.component';
import { WelcomeUserComponent } from './components/auth-modal/welcome-user/welcome-user.component';

import { ForgotPasswordComponent } from './components/auth-modal/forgot-password/forgot-password.component';
import { ForgotPasswordFormComponent } from './components/auth-modal/forgot-password/form/form.component';
import { ResetPasswordSentComponent } from './components/auth-modal/reset-password-sent/reset-password-sent.component';
import { ResetPasswordComponent } from './components/auth-modal/reset-password/reset-password.component';
import { ResetPasswordFormComponent } from './components/auth-modal/reset-password/form/form.component';

@NgModule({
  declarations: [
    InputComponent,
    ButtonComponent,
    IconComponent,
    PropertyListComponent,
    PropertyListInfoComponent,
    PropertyListStatusComponent,
    PropertyListLabelComponent,
    PropertyShareButtonComponent,
    PropertyShareCopyLinkComponent,
    PropertyShareModalComponent,
    PropertyShareEmailsComponent,
    FavoritePropertyComponent,
    ContainerComponent,
    PageTitleComponent,
    ModalComponent,
    PaginatorComponent,
    LoadingComponent,
    OfferModalComponent,
    NoResultsComponent,
    CarouselComponent,
    TopbarComponent,
    MenuMobileComponent,
    UserComponent,
    FooterComponent,
    ProfileLayoutComponent,
    ProfileSidebarComponent,
    FiltersComponent,
    FilterStatesComponent,
    FilterStatusComponent,
    FiltersFilterPriceComponent,
    FilterBedroomsComponent,
    FilterBathroomsComponent,
    FilterSubtypeComponent,
    FilterSquareFootComponent,
    FilterLotSizeComponent,
    FilterYearBuiltComponent,
    FilterCountComponent,
    HtmlFormatPipe,
    StatusPipe,
    StringPipe,
    PageUrlPipe,
    PriceLabelPipe,
    MultiSelectorComponent,
    FiltersV2Component,
    FilterV2ButtonsComponent,
    FilterV2MarketsComponent,
    FilterV2StatusComponent,
    FilterV2PriceComponent,
    FilterV2SubtypeComponent,
    FilterV2MoreComponent,
    FilterV2MoreDesktopComponent,
    FilterV2BedsComponent,
    FilterV2BathsComponent,
    FilterV2SqftComponent,
    FilterV2LotSizeComponent,
    FilterV2YearBuiltComponent,
    FilterV2FinancialsComponent,
    FilterV2ZipComponent,

    AuthModalComponent,
    AuthModalHeaderComponent,
    SignInComponent,
    SignInFormComponent,
    SignUpComponent,
    SignUpFormComponent,
    VerifyIdentityComponent,
    VerifyIdentityFormComponent,
    VerifyIdentityPhoneNumberComponent,
    VerifyIdentityUpdatePhoneComponent,
    AccountSetupComponent,
    AccountSetupFormComponent,
    WelcomeUserComponent,
    ForgotPasswordComponent,
    ForgotPasswordFormComponent,
    ResetPasswordSentComponent,
    ResetPasswordComponent,
    ResetPasswordFormComponent,
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    RouterModule,
    MatProgressBarModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSliderModule,
    FormsModule,
    LightgalleryModule,
    TippyDirective,
  ],
  exports: [
    InputComponent,
    ButtonComponent,
    IconComponent,
    PropertyListComponent,
    PropertyListStatusComponent,
    FavoritePropertyComponent,
    ProfileLayoutComponent,
    ContainerComponent,
    PageTitleComponent,
    ModalComponent,
    PaginatorComponent,
    LoadingComponent,
    OfferModalComponent,
    NoResultsComponent,
    CarouselComponent,
    MultiSelectorComponent,
    TopbarComponent,
    FooterComponent,
    FiltersComponent,
    FiltersV2Component,
    HtmlFormatPipe,
    StatusPipe,
    StringPipe,
    PriceLabelPipe,
    AuthModalComponent,
    PropertyShareModalComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
