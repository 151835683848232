import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { signInAction, setAuthModal } from '@state/auth/auth.actions';
import { selectAuthLoading } from '@state/auth/auth.selectors';

@UntilDestroy()
@Component({
  selector: 'app-signin-modal-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class SignInFormComponent implements OnInit {
  protected loading = false;
  protected focused = '';
  protected passwordVisibility = 'password';
  public signInForm: FormGroup = this.formBuilder.group({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.store
      .select(selectAuthLoading)
      .pipe(untilDestroyed(this))
      .subscribe((loading) => {
        this.loading = loading;
        if (loading) {
          this.signInForm.disable();
        } else {
          this.signInForm.enable();
        }
      });
  }

  submitHandler() {
    if (this.signInForm.invalid) {
      this.signInForm.markAllAsTouched();
      return;
    }
    const values = this.signInForm.value;
    this.store.dispatch(signInAction({ ...values }));
  }

  openForgotPassword() {
    this.store.dispatch(setAuthModal({ authModal: 'forgot-password' }));
  }

  focusHandler(event: FocusEvent) {
    const name = (event.target as HTMLInputElement).name;
    this.focused = name;
  }

  blurHandler(event: FocusEvent) {
    const name = (event.target as HTMLInputElement).name;
    if (this.focused === name) this.focused = '';
  }
}
