<div class="wrapper">
  <h1 class="title">
    <img
      *ngIf="slug === 'welcome-user'"
      src="/assets/images/party.svg"
      alt="party icon"
    />

    {{
      slug === 'signup'
        ? 'Sign up'
        : slug === 'verify-identity'
        ? 'Enter verification code'
        : slug === 'account-setup'
        ? 'Account setup'
        : slug === 'welcome-user' && !!user
        ? 'Welcome, ' + user.firstName + '!'
        : slug === 'forgot-password'
        ? 'Forgot your password?'
        : slug === 'reset-password-sent'
        ? 'Reset password email sent'
        : slug === 'reset-password'
        ? 'Reset your password'
        : 'Sign in'
    }}
  </h1>
</div>
