import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take } from 'rxjs';

import { DEFAULT_FEATURE_FLAGS } from '@core/constants/feature-flags';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectConfigOpenModal } from '@state/config/config.selectors';
import { selectAuthToken } from '@state/auth/auth.selectors';
import { setReferrerCodeAction, setAuthModal } from '@state/auth/auth.actions';
import { fetchFeatureFlagsAction } from '@state/config/config.actions';

import { environment } from '../environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  protected title = 'marketplace-frontend';
  public maintenance = false;

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {
    if (!environment.production) {
      console.log('Environment: ', environment.name);
    }
  }

  ngOnInit(): void {
    this.handleRefCode();
    if (this.maintenance) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart && event.url !== '/maintenance') {
          this.router.navigate(['/maintenance']);
        }
      });
      return;
    } else {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart && event.url === '/maintenance') {
          this.router.navigate(['/']);
        }
      });
    }
    this.fetchFeatureFlags();

    this.store
      .select(selectConfigOpenModal)
      .pipe(untilDestroyed(this))
      .subscribe((openModal) => {
        if (!!openModal) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
      });
  }

  fetchFeatureFlags(): void {
    this.store.dispatch(
      fetchFeatureFlagsAction({ keys: [...DEFAULT_FEATURE_FLAGS] })
    );
  }

  getUrl(): string {
    return window.location.href;
  }

  handleRefCode() {
    const url = this.getUrl();
    if (url.includes('?refCode=')) {
      const referrerCode = url.split('?refCode=')[1].split('&')[0];
      if (referrerCode) {
        this.store
          .select(selectAuthToken)
          .pipe(take(1))
          .subscribe((token) => {
            if (!token) {
              this.store.dispatch(setReferrerCodeAction({ referrerCode }));
            }
          });
        this.store.dispatch(setAuthModal({ authModal: 'signup' }));
      }
    }
  }
}
