import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

import {
  LIST_OWN_BUY_BOXES,
  FETCH_BUY_BOX,
} from '@graphql/queries/buy-boxes.queries';
import {
  CREATE_USER_BUY_BOX,
  UPDATE_USER_BUY_BOX,
  DELETE_USER_BOX_BUY_BOX,
} from '@graphql/mutations/buy-boxes.mutations';
import {
  IPageOfBuyBoxesData,
  IPageOfBuyBoxesInput,
  ICreateUserBuyBoxInput,
  ICreateUserBuyBoxResult,
  IUpdateBuyBoxInput,
  IUpdateUserBuyBoxResult,
  IDeleteUserBuyBoxResult,
} from '@shared/models/buy-boxes';

@Injectable({
  providedIn: 'root',
})
export class BuyBoxesService {
  constructor(private apollo: Apollo) {}

  fetchBuyBoxes(input: IPageOfBuyBoxesInput): Observable<IPageOfBuyBoxesData> {
    return this.apollo
      .query({
        query: LIST_OWN_BUY_BOXES,
        variables: {
          ...input,
          sort: [{ field: 'INSERTED_AT', order: 'DESC' }],
        },
      })
      .pipe(map((result) => result.data as IPageOfBuyBoxesData));
  }

  fetchBuyBox(id: string): Observable<IPageOfBuyBoxesData> {
    return this.apollo
      .query({
        query: FETCH_BUY_BOX,
        variables: { id },
      })
      .pipe(map((result) => result.data as IPageOfBuyBoxesData));
  }

  createUserBuyBox(
    input: ICreateUserBuyBoxInput
  ): Observable<ICreateUserBuyBoxResult> {
    return this.apollo
      .mutate({
        mutation: CREATE_USER_BUY_BOX,
        variables: input,
      })
      .pipe(map((result) => result.data as ICreateUserBuyBoxResult));
  }

  updateUserBuyBox(
    input: IUpdateBuyBoxInput
  ): Observable<IUpdateUserBuyBoxResult> {
    return this.apollo
      .mutate({
        mutation: UPDATE_USER_BUY_BOX,
        variables: input,
      })
      .pipe(map((result) => result.data as IUpdateUserBuyBoxResult));
  }

  deleteUserBuyBox(id: string): Observable<IDeleteUserBuyBoxResult> {
    return this.apollo
      .mutate({
        mutation: DELETE_USER_BOX_BUY_BOX,
        variables: { id },
      })
      .pipe(map((result) => result.data as IDeleteUserBuyBoxResult));
  }
}
