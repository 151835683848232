import * as Sentry from '@sentry/angular-ivy';

import { environment } from './environments/environment';

const envToExclude = ['Local'];

Sentry.init({
  enabled: !!environment.name && !envToExclude.includes(environment.name),
  environment: environment.name,

  dsn: 'https://e2a6ee40c3044ccdab9a4f1e7d9560d9@o4504601101467648.ingest.sentry.io/4504725289697280',

  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost:4200',
    /^https:\/\/frontend-develop\.d1ek9lvhu5u19c\.amplifyapp\.com/,
    /^https:\/\/marketplace\.rebuilt\.com/,
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  beforeSend(event, hint) {
    if (event.request?.url?.startsWith('chrome-extension://')) {
      return null;
    }
    if (event.request?.url?.includes('maps/api/js')) {
      return null;
    }
    return event;
  },
});
