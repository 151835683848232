import { IEnvironment } from '@shared/models/env';

export const environment: IEnvironment = {
  production: true,
  name: 'Production',
  url: 'https://marketplace.rebuilt.com',
  api: 'https://marketplace-prod-backend.rebuilt.com',
  maps: 'AIzaSyAy-3JwcQeSKPUgOLGJ9RvJiyFCIKZ8vr4',
  sellerUrl:
    'https://marketplace-prod-backend.rebuilt.com/iframe/property-details',
  smartyCode: '185524070255480303',
  recaptchaKey: '6Lcfhc0pAAAAAJM6-8Rx5L9H6j-wL7WYi2a7f9rc',
  recaptchaCheckURL:
    'https://b13egb814g.execute-api.us-east-1.amazonaws.com/prod/recaptchaCheck',
  recaptchaCheckKey: 'rki6drf$Uei1hFH7QdA*wZ@yUdUd0V',
};
