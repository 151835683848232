<div class="background">
  <div class="foreground"></div>
</div>

<app-shared-layout-topbar [guest]="true"></app-shared-layout-topbar>

<div class="wrapper">
  <div class="container" [ngClass]="{ 'hide-auth-info': !info }">
    <div *ngIf="info" class="auth-info">
      <h1>
        {{ name === 'Password' ? 'Sign in' : name }} to view our exclusive
        channel of off-market properties
      </h1>
      <ul>
        <li>
          <div class="icon">
            <img
              src="/assets/images/modules/auth/home-search.svg"
              alt="home search"
            />
          </div>
          Reduce your reliance on MLS
        </li>
        <li>
          <div class="icon">
            <img src="/assets/images/modules/auth/eco.svg" alt="eco" />
          </div>
          Sustainable and consistent deal flow
        </li>
        <li>
          <div class="icon">
            <img src="/assets/images/modules/auth/policy.svg" alt="eco" />
          </div>
          Filter properties by your buying criteria
        </li>
        <li>
          <div class="icon">
            <img src="/assets/images/modules/auth/like.svg" alt="like" />
          </div>
          Search for properties by market
        </li>
        <li>
          <div class="icon">
            <img
              src="/assets/images/modules/auth/verified.svg"
              alt="verified"
            />
          </div>
          Deep, unparalleled local market knowledge
        </li>
      </ul>

      <p *ngIf="name === 'Sign in'">
        Don't have an account? <a routerLink="/auth/sign-up">Click here</a> to
        Sign up
      </p>
      <p *ngIf="name === 'Sign up' || name === 'Password'">
        Already have an account? <a routerLink="/auth/sign-in">Click here</a> to
        Sign in
      </p>
    </div>
    <div class="aside">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<app-shared-layout-footer></app-shared-layout-footer>
