import { createAction, props } from '@ngrx/store';

import { IGraphQLErrors } from '@shared/models/graphql';
import {
  ISignInInput,
  ISignUpInput,
  IPasswordResetWithPasswordInput,
  IConfirmNumberInput,
  AuthModal,
  ISignUp,
} from '@shared/models/auth';
import {
  IUser,
  IUserWithToken,
  IUserPreference,
  IUserPreferenceInput,
} from '@shared/models/user';
import {
  IUpdateProfileData,
  IUpdateProfileInput,
  IUpdatePasswordInput,
  IUpdatePhoneNumberInput,
} from '@shared/models/profile';

export const setLoadingAction = createAction(
  '[Auth] Set Loading',
  props<{ loading: boolean }>()
);

export const signInAction = createAction(
  '[Auth] Sign In',
  props<ISignInInput>()
);

export const signInActionSuccess = createAction(
  '[Auth] Sign In Success',
  props<IUserWithToken>()
);

export const signInActionFailure = createAction(
  '[Auth] Sign In Failure',
  props<IGraphQLErrors>()
);

export const signUpAction = createAction(
  '[Auth] Sign Up',
  props<ISignUpInput>()
);

export const signUpActionSuccess = createAction(
  '[Auth] Sign Up Success',
  props<ISignUp>()
);

export const signUpActionFailure = createAction(
  '[Auth] Sign Up Failure',
  props<IGraphQLErrors>()
);

export const signOutAction = createAction('[Auth] Sign Out');

export const setupAccountAction = createAction(
  '[Auth] Setup Account',
  props<IUserPreferenceInput>()
);

export const setupAccountActionSuccess = createAction(
  '[Auth] Setup Account Success',
  props<IUserPreference>()
);

export const setupAccountActionFailure = createAction(
  '[Auth] Setup Account Failure',
  props<IGraphQLErrors>()
);

export const fetchUserAction = createAction('[Auth] Fetch User');

export const fetchUserActionSuccess = createAction(
  '[Auth] Fetch User Success',
  props<IUser>()
);

export const clearRequestPasswordResetAction = createAction(
  '[Auth] Clear Request Password Reset'
);

export const requestPasswordResetAction = createAction(
  '[Auth] Request Password Reset',
  props<{ email: string }>()
);

export const requestPasswordResetActionFinish = createAction(
  '[Auth] Request Password Reset Finish',
  props<{ success: boolean }>()
);

export const resetPasswordAction = createAction(
  '[Auth] Reset Password',
  props<IPasswordResetWithPasswordInput>()
);

export const resetPasswordActionFinish = createAction(
  '[Auth] Reset Password Finish'
);

export const sendPhoneNumberConfirmationAction = createAction(
  '[Auth] Send Phone Number Confirmation'
);

export const confirmPhoneNumberAction = createAction(
  '[Auth] Confirm Phone Number',
  props<IConfirmNumberInput>()
);

export const updatePhoneNumberAction = createAction(
  '[Auth] Update Phone Number',
  props<IUpdatePhoneNumberInput>()
);

export const updatePhoneNumberSuccessAction = createAction(
  '[Auth] Update Phone Number Success',
  props<{ success: boolean; user: IUser }>()
);

export const setReferrerCodeAction = createAction(
  '[Auth] Set Referrer Code',
  props<{ referrerCode: string | null }>()
);

export const setLastSMSSentAction = createAction(
  '[Auth] Set SMS Last Sent',
  props<{ lastSMSSent?: string }>()
);

export const updateProfileAction = createAction(
  '[Auth] Update Profile',
  props<IUpdateProfileInput>()
);

export const updateProfileActionSuccess = createAction(
  '[Auth] Update Profile Success',
  props<IUpdateProfileData>()
);

export const updateProfileActionFailure = createAction(
  '[Auth] Update Profile Failure',
  props<IGraphQLErrors>()
);

export const updatePasswordAction = createAction(
  '[Auth] Update Password',
  props<IUpdatePasswordInput>()
);

export const updatePasswordActionSuccess = createAction(
  '[Auth] Update Password Success'
);

export const updatePasswordActionFailure = createAction(
  '[Auth] Update Password Failure',
  props<IGraphQLErrors>()
);

export const setAuthModal = createAction(
  '[Auth] Set Auth Modal',
  props<{ authModal?: AuthModal }>()
);
