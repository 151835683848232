<div class="filter-price-wrapper filter-item">
  <div class="upper-label">
    Price: <span>{{ { min: minValue, max: maxValue } | priceLabel }}</span>
  </div>

  <div class="slider-wrapper" [ngClass]="{ active: display }">
    <mat-slider
      #matSlider
      [min]="config.min"
      [max]="config.max"
      (slideend)="onSlideEnd()"
      (pointerup)="onSlideEnd()"
    >
      <input #inputMin matSliderStartThumb [(ngModel)]="minValue" />
      <input #inputMax matSliderEndThumb [(ngModel)]="maxValue" />
    </mat-slider>
  </div>
</div>
