<form [formGroup]="resetPasswordForm" (ngSubmit)="submitHandler()">
  <app-shared-input
    label="New password"
    [for]="'password'"
    [value]="resetPasswordForm.value.password"
    [focused]="focused === 'password'"
    [touched]="resetPasswordForm.get('password')?.touched"
    [errors]="resetPasswordForm.get('password')?.errors"
    [disabled]="loading"
  >
    <input
      formControlName="password"
      name="password"
      id="password"
      type="password"
      (focus)="focusHandler($event)"
      (blur)="blurHandler($event)"
    />
  </app-shared-input>
  <app-shared-input
    label="Password confirmation"
    [for]="'passwordConfirmation'"
    [value]="resetPasswordForm.value.passwordConfirmation"
    [focused]="focused === 'passwordConfirmation'"
    [touched]="resetPasswordForm.get('passwordConfirmation')?.touched"
    [errors]="resetPasswordForm.get('passwordConfirmation')?.errors"
    [disabled]="loading"
  >
    <input
      formControlName="passwordConfirmation"
      name="passwordConfirmation"
      id="passwordConfirmation"
      type="password"
      (focus)="focusHandler($event)"
      (blur)="blurHandler($event)"
    />
  </app-shared-input>

  <div class="mt-10">
    <app-shared-button [loading]="loading">Confirm</app-shared-button>
  </div>
</form>
