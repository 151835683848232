import { gql, TypedDocumentNode } from 'apollo-angular';

import {
  IPageOfPropertyInput,
  IPageOfPropertyData,
  IGetValidPropertyData,
} from '@shared/models/properties';

export const PROPERTY_LIST_FRAGMENT = gql`
  fragment PropertyListFragment on Property {
    id
    price
    status
    isFavorite @include(if: $isLoggedIn)
    images {
      uuid
      thumbnail {
        url
      }
      small {
        url
      }
    }

    street @include(if: $isLoggedIn)
    houseNumber @include(if: $isLoggedIn)
    city
    state
    zip
    latitude @include(if: $isLoggedIn)
    longitude @include(if: $isLoggedIn)

    bedrooms @include(if: $isLoggedIn)
    bathrooms @include(if: $isLoggedIn)
    squareFoot @include(if: $isLoggedIn)
    type
    subType
    yearBuilt

    organization {
      id
      name
      shortName
    }

    offMarket {
      agentName
      agentPhoneNumber
      companyName
      companyPhoneNumber
    }

    # proForma {
    # capitalizationRate
    # grossYield
    # projectRoi
    # }

    insertedAt
  }
`;

const PROPERTY_FRAGMENT = gql`
  ${PROPERTY_LIST_FRAGMENT}
  fragment PropertyFragment on Property {
    ...PropertyListFragment
    description @include(if: $isLoggedIn)
    repairs @include(if: $isLoggedIn)
    images {
      uuid
      thumbnail {
        url
      }
      small {
        url
      }
      medium {
        url
      }
      large {
        url
      }
    }

    lotSize @include(if: $isLoggedIn)
    yearBuilt @include(if: $isLoggedIn)

    cmaUrl @include(if: $isLoggedIn)

    showing @include(if: $isLoggedIn) {
      floorPlanUrl
      inspectionUrl
      showDate
      timezone
      tourThreeDUrl
    }

    dispositionAgent {
      normalizedFullName
      phoneNumber
    }

    viewCount
  }
`;

export const FETCH_PROPERTIES: TypedDocumentNode<
  IPageOfPropertyData,
  IPageOfPropertyInput
> = gql`
  ${PROPERTY_LIST_FRAGMENT}
  query listValidProperties(
    $filter: PropertyFilterInput
    $limit: Int
    $offset: Int
    $sort: [PropertySortInput]
    $isLoggedIn: Boolean!
  ) {
    listValidProperties(
      filter: $filter
      limit: $limit
      offset: $offset
      sort: $sort
    ) {
      count
      results {
        ...PropertyListFragment
      }
    }
  }
`;

export const FETCH_PROPERTY: TypedDocumentNode<
  IGetValidPropertyData,
  { id: string }
> = gql`
  ${PROPERTY_FRAGMENT}
  query getValidProperty(
    $id: ID!
    $isLoggedIn: Boolean!
    $refCode: String
    $source: PropertySource
  ) {
    getValidProperty(id: $id, refCode: $refCode, source: $source) {
      ...PropertyFragment
    }
  }
`;
