import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { setAuthModal } from '@state/auth/auth.actions';
import { selectAuthRequestPasswordReset } from '@state/auth/auth.selectors';

@UntilDestroy()
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  protected sent = false;
  protected loading = false;

  constructor(public store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(selectAuthRequestPasswordReset)
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        if (!!data?.email && !data?.loading) {
          this.store.dispatch(
            setAuthModal({ authModal: 'reset-password-sent' })
          );
        }
      });
  }

  openSignIn() {
    this.store.dispatch(setAuthModal({ authModal: 'signin' }));
  }
}
