<form [formGroup]="passwordForm" (ngSubmit)="submitHandler()">
  <app-shared-input
    label="Email address"
    [for]="'email'"
    [value]="passwordForm.value.email"
    [focused]="focused"
    [touched]="passwordForm.get('email')?.touched"
    [errors]="passwordForm.get('email')?.errors"
    [disabled]="loading"
  >
    <input
      formControlName="email"
      name="email"
      id="email"
      type="email"
      (focus)="focused = true"
      (blur)="focused = false"
    />
  </app-shared-input>

  <app-shared-button [loading]="loading"> Send </app-shared-button>
</form>
