<form [formGroup]="form" (ngSubmit)="submitHandler()">
  <div class="verification-code-container">
    <input
      type="text"
      class="verification-code-input"
      formControlName="digit1"
      (keyup)="onKeyUp($event, 0)"
      (keydown)="onKeyDown($event, 0)"
      [ngClass]="{ 'with-value': form.get('digit1')?.value }"
      (paste)="onPaste($event, 0)"
      autocomplete="one-time-code"
      inputmode="numeric"
    />
    <input
      type="text"
      class="verification-code-input"
      formControlName="digit2"
      (keyup)="onKeyUp($event, 1)"
      (keydown)="onKeyDown($event, 1)"
      [ngClass]="{ 'with-value': form.get('digit2')?.value }"
      (paste)="onPaste($event, 1)"
      inputmode="numeric"
    />
    <input
      type="text"
      class="verification-code-input"
      formControlName="digit3"
      (keyup)="onKeyUp($event, 2)"
      (keydown)="onKeyDown($event, 2)"
      [ngClass]="{ 'with-value': form.get('digit3')?.value }"
      (paste)="onPaste($event, 2)"
      inputmode="numeric"
    />
    <input
      type="text"
      class="verification-code-input"
      formControlName="digit4"
      (keyup)="onKeyUp($event, 3)"
      (keydown)="onKeyDown($event, 3)"
      [ngClass]="{ 'with-value': form.get('digit4')?.value }"
      (paste)="onPaste($event, 3)"
      inputmode="numeric"
    />
    <input
      type="text"
      class="verification-code-input"
      formControlName="digit5"
      (keyup)="onKeyUp($event, 4)"
      (keydown)="onKeyDown($event, 4)"
      [ngClass]="{ 'with-value': form.get('digit5')?.value }"
      (paste)="onPaste($event, 4)"
      inputmode="numeric"
    />
    <input
      type="text"
      class="verification-code-input"
      formControlName="digit6"
      (keyup)="onKeyUp($event, 5)"
      (keydown)="onKeyDown($event, 5)"
      [ngClass]="{ 'with-value': form.get('digit6')?.value }"
      (paste)="onPaste($event, 5)"
      inputmode="numeric"
    />
  </div>

  <div class="forgot-password">
    Didn't receive a code? <a (click)="sendNewCode()">Send again</a>
  </div>

  <app-shared-button [loading]="loading"> Confirm </app-shared-button>
</form>
