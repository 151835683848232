import { createReducer, on } from '@ngrx/store';

import { IPageOfOffer } from '@shared/models/offers';

import {
  resetOffersAction,
  fetchOffersAction,
  fetchOffersActionSuccess,
  fetchOffersActionFailure,
  createOfferAction,
  createOfferActionSuccess,
  createOfferActionFailure,
  fetchPropertyOfferAction,
  fetchPropertyOfferActionFinished,
  withdrawOfferAction,
  withdrawOfferActionFinished,
  clearSuccessOfferSentAction,
} from './offers.actions';

export interface OffersState {
  loading: boolean;
  errors: boolean;
  pageOfOffers?: IPageOfOffer;
  create?: {
    loading: boolean;
    errors: boolean;
    success?: boolean;
  };
  withdraw?: { loading: boolean };
  propertyOffer?: { loading: boolean };
}

export const initialState: OffersState = {
  loading: true,
  errors: false,
};

export const offersReducer = createReducer(
  initialState,
  on(resetOffersAction, () => ({ ...initialState })),
  on(fetchOffersAction, (state) => ({
    ...state,
    loading: true,
    errors: false,
  })),
  on(fetchOffersActionSuccess, (state, pageOfOffers) => ({
    ...state,
    loading: false,
    errors: false,
    pageOfOffers: { ...pageOfOffers },
  })),
  on(fetchOffersActionFailure, (state) => ({
    ...state,
    loading: false,
    errors: true,
    pageOfOffers: undefined,
  })),
  on(createOfferAction, (state) => ({
    ...state,
    create: {
      loading: true,
      errors: false,
    },
  })),
  on(createOfferActionSuccess, (state) => ({
    ...state,
    create: {
      loading: false,
      errors: false,
      success: true,
    },
  })),
  on(createOfferActionFailure, (state) => ({
    ...state,
    create: {
      loading: false,
      errors: true,
    },
  })),
  on(clearSuccessOfferSentAction, (state) => ({
    ...state,
    create: {
      loading: false,
      errors: false,
      success: false,
    },
  })),
  on(fetchPropertyOfferAction, (state) => ({
    ...state,
    propertyOffer: { loading: true, errors: false },
  })),
  on(fetchPropertyOfferActionFinished, (state) => ({
    ...state,
    propertyOffer: { loading: false },
  })),
  on(withdrawOfferAction, (state) => ({
    ...state,
    withdraw: { loading: true },
  })),
  on(withdrawOfferActionFinished, (state) => ({
    ...state,
    withdraw: { loading: false },
  }))
);
