import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { Apollo, MutationResult } from 'apollo-angular';

import {
  FETCH_OFFERS,
  FETCH_PROPERTY_OFFER,
} from '@graphql/queries/offers.queries';
import {
  PLACE_OFFER,
  WITHDRAW_OFFER,
} from '@graphql/mutations/offers.mutations';

import {
  IPageOfOfferData,
  IPageOfOfferInput,
  IPlaceOfferInput,
  IPlaceOfferResult,
  IWithdrawOfferResult,
} from '@shared/models/offers';

@Injectable({
  providedIn: 'root',
})
export class OffersService {
  constructor(private apollo: Apollo) {}

  fetchOffers(query: IPageOfOfferInput): Observable<IPageOfOfferData> {
    return this.apollo
      .query({
        query: FETCH_OFFERS,
        variables: query,
      })
      .pipe(
        map((result) => result.data),
        catchError((error) => {
          throw error;
        })
      );
  }

  placeOffer(
    input: IPlaceOfferInput
  ): Observable<MutationResult<IPlaceOfferResult>> {
    return this.apollo
      .mutate({
        mutation: PLACE_OFFER,
        variables: input,
      })
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }

  fetchPropertyOffer(input: { propertyId: string }): Observable<any> {
    return this.apollo
      .query({
        query: FETCH_PROPERTY_OFFER,
        variables: input,
      })
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }

  withdrawOffer(input: {
    id: string;
  }): Observable<MutationResult<IWithdrawOfferResult>> {
    return this.apollo
      .mutate({
        mutation: WITHDRAW_OFFER,
        variables: input,
      })
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }
}
