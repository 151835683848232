<form [formGroup]="signInForm" (ngSubmit)="submitHandler()">
  <app-shared-input
    label="Email address"
    [for]="'email'"
    [value]="signInForm.value.email"
    [focused]="focused === 'email'"
    [touched]="signInForm.get('email')?.touched"
    [errors]="signInForm.get('email')?.errors"
    [disabled]="loading"
  >
    <input
      formControlName="email"
      name="email"
      id="email"
      type="email"
      (focus)="focusHandler($event)"
      (blur)="blurHandler($event)"
    />
  </app-shared-input>
  <app-shared-input
    label="Password"
    [for]="'password'"
    [value]="signInForm.value.password"
    [focused]="focused === 'password'"
    [touched]="signInForm.get('password')?.touched"
    [errors]="signInForm.get('password')?.errors"
    [disabled]="loading"
    type="password"
    (toggleVisibility)="passwordVisibility = $event"
  >
    <input
      formControlName="password"
      name="password"
      id="password"
      [type]="passwordVisibility"
      (focus)="focusHandler($event)"
      (blur)="blurHandler($event)"
    />
  </app-shared-input>

  <div class="forgot-password">
    <div class="link" (click)="openForgotPassword()">Forgot password?</div>
  </div>

  <app-shared-button [loading]="loading">Sign in</app-shared-button>
</form>
