import { Component, EventEmitter, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { PHONE_NUMBER_REGEX } from '@core/constants/regex';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import {
  setLoadingAction,
  updatePhoneNumberAction,
} from '@state/auth/auth.actions';
import {
  selectAuthLoading,
  selectUpdatePhoneNumberSuccess,
} from '@state/auth/auth.selectors';

@UntilDestroy()
@Component({
  selector: 'app-verify-identity-update-phone',
  templateUrl: './update-phone.component.html',
  styleUrls: ['./update-phone.component.scss'],
})
export class VerifyIdentityUpdatePhoneComponent {
  @Output() handleClose = new EventEmitter();

  protected loading$ = this.store.select(selectAuthLoading);
  protected focused = false;
  public updatePhoneNumberForm: FormGroup = this.formBuilder.group({
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.pattern(PHONE_NUMBER_REGEX),
    ]),
  });

  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder
  ) {}

  submitHandler(): void {
    if (this.updatePhoneNumberForm.invalid) {
      this.updatePhoneNumberForm.markAllAsTouched();
      return;
    }
    const phoneNumber = this.updatePhoneNumberForm.value.phoneNumber;
    this.store.dispatch(setLoadingAction({ loading: true }));
    this.store.dispatch(
      updatePhoneNumberAction({ input: { phoneNumber: '+1' + phoneNumber } })
    );
    this.store
      .select(selectUpdatePhoneNumberSuccess)
      .pipe(untilDestroyed(this))
      .subscribe((success) => {
        if (success) {
          this.handleClose.emit();
        }
      });
  }

  pastePhoneNumberHandler(event: ClipboardEvent) {
    const pastedText = event.clipboardData
      ?.getData('text/plain')
      .replace(/[\s()-]/g, '');
    setTimeout(() => {
      if (pastedText?.startsWith('+1')) {
        this.updatePhoneNumberForm.controls['phoneNumber'].setValue(
          pastedText?.substring(2, pastedText.length)
        );
      }
    }, 0);
  }
}
