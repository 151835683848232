<div class="subtitle">
  Enter your email address and we'll send you a link to set your password.
</div>

<app-forgot-password-form></app-forgot-password-form>

<div *ngIf="sent && !loading" class="sent-message">
  <p>
    Your password request has been submitted. We will deliver reset instructions
    to the email address provided if we are able to locate your account.
  </p>
</div>

<div class="footer-link">
  Did you remember it?
  <div (click)="openSignIn()" class="link">Sign in</div>
</div>
