import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-verify-identity',
  templateUrl: './verify-identity.component.html',
  styleUrls: ['./verify-identity.component.scss'],
})
export class VerifyIdentityComponent {
  protected open = true;

  toggleOpen(value: boolean): void {
    this.open = value;
  }
}
