import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { syncPropertyReferrals } from '@core/utils/referrals';
import { ACCOUNT_SETUP_MIN_DATE } from '@core/constants/auth';
import { PropertiesService } from '@core/services/properties.service';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { fetchUserAction, setAuthModal } from '@state/auth/auth.actions';
import { selectAuthToken, selectAuthUser } from '@state/auth/auth.selectors';

@UntilDestroy()
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  protected token: string | undefined = undefined;
  protected isDetailsScreen = false;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private propertiesService: PropertiesService
  ) {}

  ngOnInit(): void {
    this.isDetailsScreen = !!this.router.url.startsWith('/properties/');
    if (
      !this.router.url.startsWith('/maintenance') &&
      !this.router.url.startsWith('/disclosures')
    ) {
      this.store.select(selectAuthToken).subscribe((token) => {
        if (token && token !== this.token) this.fetchUser();
        this.token = token;
      });
    }
  }

  fetchUser(): void {
    this.store.dispatch(fetchUserAction());
    this.store
      .select(selectAuthUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        if (user) {
          if (user.confirmedAt === null) {
            this.store.dispatch(setAuthModal({ authModal: 'verify-identity' }));
            return;
          }
          if (
            (!user.preference || !user.preference.roles) &&
            new Date(user.insertedAt) >= ACCOUNT_SETUP_MIN_DATE
          ) {
            this.store.dispatch(setAuthModal({ authModal: 'account-setup' }));
            return;
          }
          if (user.isActive === false) {
            this.router.navigate(['/auth', 'deactivated']);
            return;
          }
          syncPropertyReferrals(this.store, this.propertiesService);
        }
      });
  }
}
