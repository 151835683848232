import { gql, TypedDocumentNode } from 'apollo-angular';

import { IPropertyProFormaData } from '@shared/models/pro-forma';

export const PRO_FORMA_FROM_PROPERTY: TypedDocumentNode<
  IPropertyProFormaData,
  { propertyId: string }
> = gql`
  query getProFormaFromProperty($propertyId: ID!) {
    getProFormaFromProperty(propertyId: $propertyId) {
      id
      purchasePrice

      rentRepairEstimate
      marketRent
      vacancy
      propertyTax
      homeOwnersAssociationFees
      propertyManagement
      leasingFees
      insurance
      repairsAndMaintenance

      flipRepairEstimate
      afterRepairValue
      holdingsCosts
      listingExpenses
    }
  }
`;
