import { Pipe, PipeTransform } from '@angular/core';

import { MAX_PRICE } from '../filter-price/filter-price.component';

@Pipe({
  name: 'priceLabel',
})
export class PriceLabelPipe implements PipeTransform {
  public maxPrice = MAX_PRICE;

  transform(value: { min: number; max: number }, ...args: unknown[]): unknown {
    const { min: minValue, max: maxValue } = value;

    if (minValue === maxValue) {
      if (minValue >= 1000) {
        const formattedValue = Math.round(minValue / 100) / 10;
        return `$${formattedValue}M`;
      } else {
        return `$${minValue}K`;
      }
    }

    if (minValue === 1 && maxValue === this.maxPrice) {
      return 'any';
    }

    let minLabel = `${minValue}K`;
    if (minValue <= 1) {
      minLabel = 'or less';
    } else if (minValue >= 1000) {
      minLabel = `${this.calcMillions(minValue)}M`;
    }

    let maxLabel = `${maxValue}K`;
    if (maxValue === this.maxPrice) {
      maxLabel = 'or more';
    } else if (maxValue >= 1000) {
      maxLabel = `${this.calcMillions(maxValue)}M`;
    }

    return minLabel === 'or less'
      ? `$${maxLabel} or less`
      : maxLabel === 'or more'
      ? `$${minLabel} or more`
      : `$${minLabel} - $${maxLabel}`;
  }

  calcMillions(value: number) {
    return Math.round((value * 10) / 100) / 100;
  }
}
