import { gql, TypedDocumentNode } from 'apollo-angular';

import {
  IUpdateProfileData,
  IUpdateProfileInput,
  IUpdatePasswordData,
  IUpdatePasswordInput,
} from '@shared/models/profile';

import { USER_FRAGMENT } from './auth.mutations';
import { MUTATION_ERROR_FRAGMENT } from '../errors.fragments';

export const UPDATE_PROFILE: TypedDocumentNode<
  IUpdateProfileData,
  IUpdateProfileInput
> = gql`
  ${USER_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      result {
        ...UserFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;

export const UPDATE_PASSWORD: TypedDocumentNode<
  IUpdatePasswordData,
  IUpdatePasswordInput
> = gql`
  ${USER_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      result {
        ...UserFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;
