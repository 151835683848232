import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IFeatureFlagsRes } from '@shared/models/feature-flags';
import { GET_FEATURE_FLAGS } from '@graphql/queries/feature-flags.queries';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectConfigFeatureFlags } from '@state/config/config.selectors';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  constructor(
    private router: Router,
    private apollo: Apollo,
    private store: Store<AppState>
  ) {}

  getFeatureFlags(keys: string[]): Observable<IFeatureFlagsRes> {
    return this.apollo
      .query({
        query: GET_FEATURE_FLAGS,
        variables: { keys },
      })
      .pipe(map((result) => result.data as IFeatureFlagsRes));
  }

  isEnabled(key: string): Observable<boolean | null> {
    return this.store.select(selectConfigFeatureFlags).pipe(
      untilDestroyed(this),
      map((featureFlags) => {
        if (featureFlags.items.length) {
          const featureFlag = featureFlags.items.find(
            (item) => item.key === key
          );
          if (featureFlag) {
            return featureFlag.isEnabled;
          }
        }
        return null;
      })
    );
  }

  redirectIfDisabled(key: string, path?: string[]): void {
    this.isEnabled(key)
      .pipe(untilDestroyed(this))
      .subscribe((enabled) => {
        if (enabled === false) {
          this.router.navigate(path || ['/']);
        }
      });
  }
}
