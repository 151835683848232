import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { OffersService } from '@core/services/offers.service';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { fetchPropertyOffer } from '@state/properties/properties.actions';

import {
  fetchOffersAction,
  fetchOffersActionSuccess,
  fetchOffersActionFailure,
  createOfferAction,
  createOfferActionSuccess,
  createOfferActionFailure,
  fetchPropertyOfferAction,
  fetchPropertyOfferActionFinished,
  withdrawOfferAction,
  withdrawOfferActionFinished,
} from './offers.actions';

@Injectable()
export class OffersEffects {
  constructor(
    private actions$: Actions,
    private offersService: OffersService,
    private toastr: ToastrService,
    private store: Store<AppState>
  ) {}

  fetchOffers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchOffersAction),
      switchMap((query) =>
        this.offersService.fetchOffers(query).pipe(
          map((res) => {
            if (res?.listOwnOffers) {
              return fetchOffersActionSuccess(res?.listOwnOffers);
            } else {
              throw Error('No offers found');
            }
          }),
          catchError((error) => {
            return [fetchOffersActionFailure()];
          })
        )
      )
    )
  );

  createOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOfferAction),
      switchMap((action) =>
        this.offersService.placeOffer(action).pipe(
          map((res) => {
            if (res?.data?.placeOffer.result) {
              this.store.dispatch(
                fetchPropertyOffer(res.data.placeOffer.result)
              );
              return createOfferActionSuccess();
            } else {
              throw res.data?.placeOffer?.errors;
            }
          }),
          catchError((error) => {
            this.toastr.error('The offer could not be created');
            return [createOfferActionFailure()];
          })
        )
      )
    )
  );

  fetchPropertyOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPropertyOfferAction),
      switchMap((action) =>
        this.offersService.fetchPropertyOffer(action).pipe(
          map((res) => {
            if (res?.data?.getOwnValidOffer) {
              this.store.dispatch(
                fetchPropertyOffer(res.data.getOwnValidOffer)
              );
              return fetchPropertyOfferActionFinished();
            } else {
              throw Error('No offers found');
            }
          }),
          catchError((error) => {
            return [fetchPropertyOfferActionFinished()];
          })
        )
      )
    )
  );

  withdrawOffer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(withdrawOfferAction),
      switchMap((action) =>
        this.offersService.withdrawOffer(action).pipe(
          map((res) => {
            if (res?.data?.withdrawOffer.result) {
              this.toastr.success('The offer was withdrawn');
              this.store.dispatch(
                fetchPropertyOffer(res.data.withdrawOffer.result)
              );
              return withdrawOfferActionFinished();
            } else {
              throw res.data?.withdrawOffer?.errors;
            }
          }),
          catchError((error) => {
            this.toastr.error('The offer could not be withdrawn');
            return [withdrawOfferActionFinished()];
          })
        )
      )
    )
  );
}
