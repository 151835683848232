<form [formGroup]="updatePhoneNumberForm" (ngSubmit)="submitHandler()">
  <app-shared-input
    label="Phone number"
    for="phoneNumber"
    [value]="updatePhoneNumberForm.value.phoneNumber"
    [focused]="focused"
    [touched]="updatePhoneNumberForm.get('phoneNumber')?.touched"
    [errors]="updatePhoneNumberForm.get('phoneNumber')?.errors"
    [disabled]="!!(loading$ | async)"
    prefix="+1"
  >
    <input
      formControlName="phoneNumber"
      name="phoneNumber"
      id="phoneNumber"
      type="tel"
      (paste)="pastePhoneNumberHandler($event)"
      (focus)="focused = true"
      (blur)="focused = false"
      mask="(000) 000-0000"
    />
  </app-shared-input>

  <div class="actions">
    <app-shared-button size="small" [loading]="!!(loading$ | async)">
      Update
    </app-shared-button>
  </div>
</form>
