import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IFilterNumber } from '@shared/models/graphql';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectFiltersCurrent } from '@state/filters/filters.selectors';
import { selectConfigOpenModal } from '@state/config/config.selectors';
import { setUnsavedPriceFiltersAction } from '@state/filters/filters.actions';

export const MAX_PRICE = 600;

@UntilDestroy()
@Component({
  selector: 'app-filters-filter-price',
  templateUrl: './filter-price.component.html',
  styleUrls: ['../filters.component.scss', './filter-price.component.scss'],
})
export class FiltersFilterPriceComponent implements OnInit, AfterViewInit {
  @ViewChild('inputMin') inputMin?: ElementRef<HTMLInputElement>;
  @ViewChild('inputMax') inputMax?: ElementRef<HTMLInputElement>;

  protected display = false;
  public minValue = 1;
  public maxValue = MAX_PRICE;
  protected config = {
    min: 1,
    max: MAX_PRICE,
  };

  constructor(
    private store: Store<AppState>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store
      .select(selectConfigOpenModal)
      .pipe(untilDestroyed(this))
      .subscribe((open) => {
        if (!open) {
          this.display = false;
          this.cdRef.detectChanges();
        } else {
          setTimeout(() => {
            this.display = true;
          }, 0);
        }
      });

    this.store
      .select(selectFiltersCurrent)
      .pipe(untilDestroyed(this))
      .subscribe((filters) => {
        if (filters?.price) {
          if (filters.price.eq) {
            this.minValue = this.maxValue = filters.price.eq / 1000;
          } else {
            if (filters.price.greaterThanOrEqual) {
              this.minValue = filters.price.greaterThanOrEqual / 1000;
            }
            if (filters.price.lessThanOrEqual) {
              this.maxValue = filters.price.lessThanOrEqual / 1000;
            }
          }
        } else {
          this.minValue = 1;
          this.maxValue = MAX_PRICE;
        }
      });
  }

  ngAfterViewInit() {
    this.onInputChange();
  }

  onInputChange() {
    const minValue = this.minValue * 1000;
    const maxValue = this.maxValue * 1000;

    let priceQuery: IFilterNumber | undefined = {};
    if (minValue === maxValue) {
      priceQuery = { eq: minValue };
    } else if (minValue === 1000 && maxValue === MAX_PRICE) {
      priceQuery = undefined;
    } else {
      if (minValue > 1000) {
        priceQuery = { greaterThanOrEqual: minValue };
      }
      if (maxValue < MAX_PRICE * 1000) {
        priceQuery = { ...priceQuery, lessThanOrEqual: maxValue };
      }
    }

    this.store.dispatch(setUnsavedPriceFiltersAction({ ...priceQuery }));
  }

  onSlideEnd() {
    this.inputMin?.nativeElement.blur();
    this.inputMax?.nativeElement.blur();
    this.onInputChange();
  }
}
