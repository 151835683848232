import { gql, TypedDocumentNode } from 'apollo-angular';

import {
  ISetAsFavoriteResult,
  ISetAsFavoriteInput,
  IRemoveFromFavoriteResult,
  IRemoveFromFavoriteInput,
} from '@shared/models/favorites';

import { MUTATION_ERROR_FRAGMENT } from '../errors.fragments';
import { PROPERTY_LIST_FRAGMENT } from '../queries/properties.queries';

export const SET_AS_FAVORITE: TypedDocumentNode<
  ISetAsFavoriteResult,
  ISetAsFavoriteInput
> = gql`
  ${PROPERTY_LIST_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
  mutation setAsFavorite($id: ID!, $isLoggedIn: Boolean!) {
    setAsFavorite(id: $id) {
      result {
        ...PropertyListFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;

export const REMOVE_FROM_FAVORITE: TypedDocumentNode<
  IRemoveFromFavoriteResult,
  IRemoveFromFavoriteInput
> = gql`
  ${PROPERTY_LIST_FRAGMENT}
  ${MUTATION_ERROR_FRAGMENT}
  mutation removeFromFavorite($id: ID!, $isLoggedIn: Boolean!) {
    removeFromFavorite(id: $id) {
      result {
        ...PropertyListFragment
      }
      errors {
        ...ErrorFragment
      }
    }
  }
`;
