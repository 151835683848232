import { createSelector } from '@ngrx/store';

import { AppState } from '../app.state';
import { ConfigState } from './config.reducer';

export const selectConfig = createSelector(
  (state: AppState) => state.config,
  (config: ConfigState) => config
);

export const selectConfigOpenModal = createSelector(
  selectConfig,
  (config: ConfigState) => config.openModal
);

export const selectConfigMapView = createSelector(
  selectConfig,
  (config: ConfigState) => config.mapView
);

export const selectConfigFeatureFlags = createSelector(
  selectConfig,
  (config: ConfigState) => config.featureFlags
);

export const hiddenNotices = createSelector(
  selectConfig,
  (config: ConfigState) => config.hiddenNotices
);
