<app-auth-wrapper name="Verify your identity" [info]="false">
  <div class="verify-identity-content text-center" *ngIf="!loading">
    <h1>Acount deactivated</h1>

    <div class="opacity-60">
      <p>We're sorry, but your account has been deactivated.</p>
      <p class="mt-4">
        To reactivate your account or for further assistance, please contact our
        support team.
      </p>
    </div>

    <div class="sign-up">
      <div class="line">or</div>
      <a routerLink="/logout"> Sign in with a different account </a>
    </div>
  </div>
</app-auth-wrapper>
