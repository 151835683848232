import { ActionReducerMap, ActionReducer, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { configReducer } from './config/config.reducer';
import { authReducer } from './auth/auth.reducer';
import { filtersReducer } from './filters/filters.reducer';
import { propertiesReducer } from './properties/properties.reducer';
import { offersReducer } from './offers/offers.reducer';
import { favoritesReducer } from './favorites/favorites.reducer';
import { buyBoxReducer } from './buy-boxes/buy-boxes.reducer';

import { AppState } from './app.state';

export const reducers: ActionReducerMap<AppState> = {
  config: configReducer,
  auth: authReducer,
  filters: filtersReducer,
  properties: propertiesReducer,
  offers: offersReducer,
  favorites: favoritesReducer,
  buyBoxes: buyBoxReducer,
};

const localStorageSyncReducer = (
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> => {
  return localStorageSync({
    keys: [
      { config: ['mapView', 'hiddenNotices'] },
      { auth: ['user', 'token', 'referrerCode'] },
      { filters: ['current'] },
      { properties: ['pageOfProperties', 'guestReferrals'] },
      { favorites: ['pageOfFavorites'] },
      { buyBoxes: ['pageOfBuyBoxes'] },
    ],
    rehydrate: true,
  })(reducer);
};

export const metaReducers: MetaReducer<AppState>[] = [localStorageSyncReducer];
