import { createAction, props } from '@ngrx/store';

import { TPropertySubType, TPropertyStatus } from '@shared/models/properties';
import { IFilter, IFilterNumber } from '@shared/models/graphql';
import { IFilterProForma } from '@shared/models/filters';

export const resetFiltersAction = createAction('[Filters] Reset');

export const resetUnsavedFiltersAction = createAction(
  '[Filters] Reset Unsaved'
);

export const setFiltersAction = createAction('[Filters] Set Filters');

export const setUnsavedStateFiltersAction = createAction(
  '[Filters] Set Unsaved State Filters',
  props<IFilter<string>>()
);

export const setSavedStateFiltersAction = createAction(
  '[Filters] Set Saved State Filters',
  props<IFilter<string>>()
);

export const setUnsavedStatusFiltersAction = createAction(
  '[Filters] Set Unsaved Status Filters',
  props<IFilter<TPropertyStatus>>()
);

export const setUnsavedPriceFiltersAction = createAction(
  '[Filters] Set Unsaved Price Filters',
  props<IFilterNumber>()
);

export const setUnsavedProFormaFiltersAction = createAction(
  '[Filters] Set Unsaved Pro Forma Filters',
  props<IFilterProForma>()
);

export const setUnsavedBedroomsFiltersAction = createAction(
  '[Filters] Set Unsaved Bedrooms Filters',
  props<IFilterNumber>()
);

export const setUnsavedBathroomsFiltersAction = createAction(
  '[Filters] Set Unsaved Bathrooms Filters',
  props<IFilterNumber>()
);

export const setUnsavedSubtypeFiltersAction = createAction(
  '[Filters] Set Unsaved Subtype Filters',
  props<IFilter<TPropertySubType>>()
);

export const setUnsavedSquareFootFiltersAction = createAction(
  '[Filters] Set Unsaved Square Foot Filters',
  props<IFilterNumber>()
);

export const setUnsavedLotSizeFiltersAction = createAction(
  '[Filters] Set Unsaved Lot Size Filters',
  props<IFilterNumber>()
);

export const setUnsavedYearBuiltFiltersAction = createAction(
  '[Filters] Set Unsaved Year Built Filters',
  props<IFilterNumber>()
);

export const setUnsavedZipFiltersAction = createAction(
  '[Filters] Set Unsaved Zip Filters',
  props<IFilter<string>>()
);
