import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import {
  UPDATE_PROFILE,
  UPDATE_PASSWORD,
} from '@graphql/mutations/profile.mutations';
import {
  IUpdateProfileInput,
  IUpdateProfileData,
  IUpdatePasswordInput,
  IUpdatePasswordData,
} from '@shared/models/profile';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private apollo: Apollo) {}

  updateProfile(input: IUpdateProfileInput): Observable<IUpdateProfileData> {
    return this.apollo
      .mutate({
        mutation: UPDATE_PROFILE,
        variables: input,
      })
      .pipe(map((result) => result.data as IUpdateProfileData));
  }

  updatePassword(input: IUpdatePasswordInput): Observable<IUpdatePasswordData> {
    return this.apollo
      .mutate({
        mutation: UPDATE_PASSWORD,
        variables: input,
      })
      .pipe(map((result) => result.data as IUpdatePasswordData));
  }
}
