import { createSelector } from '@ngrx/store';

import { AppState } from '../app.state';
import { FiltersState } from './filters.reducer';

export const selectFilters = createSelector(
  (state: AppState) => state.filters,
  (filters: FiltersState) => filters
);

export const selectFiltersCurrent = createSelector(
  selectFilters,
  (state: FiltersState) => state.current
);

export const selectFiltersUnsaved = createSelector(
  selectFilters,
  (state: FiltersState) => state.unsaved
);
