import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AuthModal } from '@shared/models/auth';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { setAuthModal } from '@state/auth/auth.actions';
import { selectAuthModal, selectAuthToken } from '@state/auth/auth.selectors';

const removeAlwaysHide = ['verify-identity', 'account-setup', 'welcome-user'];

@UntilDestroy()
@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss'],
})
export class AuthModalComponent implements OnInit {
  @Input() modalWrapper = true;

  protected modal?: AuthModal;
  protected loggedIn = false;
  protected alwaysHide = true;
  protected homeScreen = false;
  protected isMobile = false;

  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (
        (this.router.url === '/' ||
          this.router.url.startsWith('/page/') ||
          this.router.url.startsWith('/?')) &&
        !this.router.url.startsWith('/maintenance') &&
        !this.router.url.startsWith('/disclosures')
      ) {
        this.homeScreen = true;
      } else {
        this.homeScreen = false;
      }
    });

    this.store
      .select(selectAuthModal)
      .pipe(untilDestroyed(this))
      .subscribe((authModal) => {
        this.modal = authModal;
        if (this.modal && removeAlwaysHide.includes(this.modal)) {
          this.alwaysHide = false;
        }
      });

    this.store
      .select(selectAuthToken)
      .pipe(untilDestroyed(this))
      .subscribe((token) => {
        this.loggedIn = !!token;
        if (this.modal && removeAlwaysHide.includes(this.modal)) {
          this.alwaysHide = false;
        } else {
          this.alwaysHide = !!token;
        }
      });
  }

  onCloseHandler() {
    if (
      this.modal &&
      (!removeAlwaysHide.includes(this.modal) || this.modal === 'welcome-user')
    ) {
      this.store.dispatch(setAuthModal({ authModal: undefined }));
    } else if (this.modal === 'verify-identity') {
      this.toastr.clear();
      this.toastr.info('Verify your account to access the marketplace');
    } else if (this.modal === 'account-setup') {
      this.toastr.clear();
      this.toastr.info('Complete your account setup to access the marketplace');
    }
  }
}
