<button
  #buttonElement
  class="wrapper"
  [disabled]="loading || disabled"
  [ngClass]="{
    disabled: disabled || loading,
    loading: loading,
    'size-large': size === 'large',
    'size-small': size === 'small',
    'theme-secondary': theme === 'secondary',
    'theme-dark-blue': theme === 'dark-blue',
    'theme-light-blue': theme === 'light-blue',
    'theme-outline': theme === 'outline',
    'theme-tertiary': theme === 'tertiary',
    'theme-destruct': theme === 'destruct'
  }"
>
  <div *ngIf="loading" class="global-spinner"></div>

  <div *ngIf="icon && !loading" class="btn-icon">
    <app-shared-icon [name]="icon" [size]="18"></app-shared-icon>
  </div>

  <div *ngIf="!loading" [ngClass]="{ 'has-icon': !!icon }">
    <ng-content></ng-content>
  </div>
</button>
