import { gql, TypedDocumentNode } from 'apollo-angular';

import {
  IOffer,
  IPageOfOfferData,
  IPageOfOfferInput,
} from '@shared/models/offers';

export const OFFER_FRAGMENT = gql`
  fragment OfferFragment on Offer {
    id
    insertedAt
    paymentType
    price
    property {
      id
      price
      street
      houseNumber
      city
      state
      zip
      images {
        uuid
        thumbnail {
          url
        }
      }
    }
    status
    updatedAt
  }
`;

export const FETCH_OFFERS: TypedDocumentNode<
  IPageOfOfferData,
  IPageOfOfferInput
> = gql`
  ${OFFER_FRAGMENT}
  query listOwnOffers($limit: Int, $offset: Int, $sort: [OfferSortInput]) {
    listOwnOffers(limit: $limit, offset: $offset, sort: $sort) {
      count
      results {
        ...OfferFragment
      }
    }
  }
`;

export const FETCH_PROPERTY_OFFER: TypedDocumentNode<
  IOffer,
  { propertyId: string }
> = gql`
  ${OFFER_FRAGMENT}
  query getOwnValidOffer($propertyId: ID!) {
    getOwnValidOffer(propertyId: $propertyId) {
      ...OfferFragment
    }
  }
`;
