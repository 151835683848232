import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { PropertiesService } from '@core/services/properties.service';

import {
  fetchPropertiesAction,
  fetchPropertiesActionSuccess,
  fetchPropertiesActionFailure,
  fetchPropertyAction,
  fetchPropertyActionSuccess,
  fetchPropertyActionFailure,
  fetchPropertyProFormaAction,
  fetchPropertyProFormaSuccess,
  fetchPropertyProFormaFailure,
} from './properties.actions';

@Injectable()
export class PropertiesEffects {
  constructor(
    private actions$: Actions,
    private propertiesService: PropertiesService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  fetchProperties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPropertiesAction),
      switchMap((query) =>
        this.propertiesService.fetchProperties(query).pipe(
          map((res) => {
            if (res?.listValidProperties) {
              return fetchPropertiesActionSuccess(res?.listValidProperties);
            } else {
              throw Error('No properties found');
            }
          }),
          catchError((error) => {
            return [fetchPropertiesActionFailure()];
          })
        )
      )
    )
  );

  fetchProperty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPropertyAction),
      switchMap((query) =>
        this.propertiesService.fetchProperty(query).pipe(
          map((res) => {
            if (res?.getValidProperty) {
              return fetchPropertyActionSuccess({
                property: res.getValidProperty,
              });
            } else {
              throw Error('This property was not found');
            }
          }),
          catchError((error) => {
            return [fetchPropertyActionFailure()];
          })
        )
      )
    )
  );

  fetchPropertyError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fetchPropertyActionFailure),
        tap(() => {
          this.toastr.error('This property was not found');
          this.router.navigate(['/']);
        })
      ),
    { dispatch: false }
  );

  fetchPropertyProForma$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchPropertyProFormaAction),
      switchMap((query) =>
        this.propertiesService.fetchPropertyProForma(query.propertyId).pipe(
          map((res) => {
            if (res?.getProFormaFromProperty) {
              return fetchPropertyProFormaSuccess({
                ...res.getProFormaFromProperty,
              });
            } else {
              throw Error('This property pro forma data was not found');
            }
          }),
          catchError((error) => {
            return [fetchPropertyProFormaFailure()];
          })
        )
      )
    )
  );
}
