import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs';

import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { selectAuthToken } from '@state/auth/auth.selectors';

import { CanActivateRes } from './auth.guard';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): CanActivateRes {
    return this.store.select(selectAuthToken).pipe(
      map((token) => {
        if (token) {
          return this.router.createUrlTree(['/']);
        }
        return true;
      })
    );
  }
}
