<div class="subtitle" *ngIf="user$ | async as user">
  <ng-container *ngIf="!open">
    We just sent a text message with a 6-digit code to (***) ***-{{
      user.phoneNumber.slice(-4)
    }}. Not your number?
    <span class="action-link" (click)="toggleOpen(true)">Update it</span>
  </ng-container>
  <ng-container *ngIf="open">
    Update your phone number.
    <!-- <span class="action-link" (click)="toggleOpen(false)">Click here</span> to
    cancel. -->
  </ng-container>
</div>

<app-verify-identity-update-phone
  *ngIf="open"
  (handleClose)="toggleOpen(false)"
></app-verify-identity-update-phone>
