import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringTransform',
})
export class StringPipe implements PipeTransform {
  transform(
    value: string,
    type:
      | 'toCamelCase'
      | 'capitalizeEachWord'
      | 'capitalizeFirstLetter'
      | 'slugify'
      | 'strPriceToDecimal'
  ): string {
    if (type === 'toCamelCase') {
      return this.toCamelCase(value);
    } else if (type === 'capitalizeEachWord') {
      return this.capitalizeEachWord(value);
    } else if (type === 'capitalizeFirstLetter') {
      return this.capitalizeFirstLetter(value);
    } else if (type === 'slugify') {
      return this.slugify(value);
    } else if (type === 'strPriceToDecimal') {
      return this.strPriceToDecimal(value);
    }
    return value;
  }

  toCamelCase(str: string): string {
    return str.toLowerCase().replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace('-', '').replace('_', '');
    });
  }

  capitalizeEachWord(str: string): string {
    const words = str.split(/[-_ ]/);
    return words
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  }

  capitalizeFirstLetter(str: string): string {
    const words = str.toLowerCase().split(/[-_ ]/);
    words[0] =
      words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
    return words.join(' ');
  }

  slugify(str: string): string {
    return str
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }

  strPriceToDecimal(str: string) {
    return parseFloat(str.replace(/[^0-9.]/g, '')).toString();
  }
}
