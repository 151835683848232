import { Component, ElementRef, Input, ViewChild } from '@angular/core';

import { TIconName } from '@shared/models/icons';

@Component({
  selector: 'app-shared-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() loading = false;
  @Input() disabled = false;

  @Input() theme:
    | 'primary'
    | 'secondary'
    | 'dark-blue'
    | 'light-blue'
    | 'outline'
    | 'tertiary'
    | 'destruct' = 'primary';
  @Input() size: 'large' | 'medium' | 'small' = 'medium';

  @Input() icon: TIconName | null = null;

  @ViewChild('buttonElement', { static: false }) buttonElement!: ElementRef;

  blurButton() {
    if (this.buttonElement) {
      this.buttonElement.nativeElement.blur();
    }
  }
}
