import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { translateInputError } from '@core/utils/errors';

@UntilDestroy()
@Component({
  selector: 'app-shared-multi-selector',
  templateUrl: './multi-selector.component.html',
  styleUrls: [
    './multi-selector.component.scss',
    '../input/input.component.scss',
  ],
})
export class MultiSelectorComponent implements AfterViewInit, OnChanges {
  @Input() label?: string;
  @Input({ required: true }) name?: string;
  @Input({ required: true }) options: { key: string; value: string }[] = [];
  @Input({ required: true }) selected: string[] = [];

  @Input() disabled: boolean = false;
  @Input() focused: boolean = false;
  @Output() handleChange = new EventEmitter<string[]>();
  @Output() handleBlur = new EventEmitter<FocusEvent>();

  @Input() touched?: boolean = false;
  @Input() errors?: ValidationErrors | null;

  @ViewChild('triggerEl') triggerEl!: ElementRef;
  @ViewChild(MatMenuTrigger, { static: false }) trigger!: MatMenuTrigger;

  protected opened = false;
  protected displayValues: { key: string; value: string }[] = [];
  protected menuConfig = { closeOnClick: false };

  protected internalTouched = false;
  protected errorMessage?: string;

  ngOnChanges(): void {
    this.handleError();
  }

  handleError() {
    if ((this.touched || this.internalTouched) && this.errors) {
      this.errorMessage = translateInputError(this.errors);
    }
  }

  toggleValue(key: string) {
    const index = this.selected.indexOf(key);
    if (index > -1) {
      this.removeValue(key);
    } else {
      this.addValue(key);
    }
  }

  addValue(key: string) {
    const index = this.selected.indexOf(key);
    if (index === -1) {
      this.selected = [...this.selected, key];
      this.displayValues = this.options.filter((o) =>
        this.selected.includes(o.key)
      );
      this.handleOnChange();
    }
  }

  removeValue(key: string) {
    const index = this.selected.indexOf(key);
    if (index > -1) {
      this.selected = this.selected.filter((s) => s !== key);
      this.displayValues = this.displayValues.filter((d) => d.key !== key);
      this.handleOnChange();
    }
  }

  handleOnChange() {
    this.handleChange.emit(this.selected);
    this.setMenuWidthPosition();
  }

  ngAfterViewInit() {
    this.trigger.menuOpened.pipe(untilDestroyed(this)).subscribe(() => {
      this.opened = true;
      this.setMenuWidthPosition();
    });
    this.trigger.menuClosed.pipe(untilDestroyed(this)).subscribe(() => {
      this.opened = false;
    });
  }

  setMenuWidthPosition() {
    const width = this.triggerEl.nativeElement.offsetWidth;
    const overlayPane = document.querySelector(
      '.cdk-overlay-pane'
    ) as HTMLElement;
    if (overlayPane) {
      overlayPane.style.width = `${width}px`;
    }
    setTimeout(() => {
      this.trigger.updatePosition();
    }, 10);
  }

  handleClick(event: MouseEvent): void {
    let classList: string[] = [];
    let targetElement: Element | null = event.target as Element;
    while (targetElement && targetElement !== this.triggerEl.nativeElement) {
      classList = classList.concat(Array.from(targetElement.classList));
      targetElement = targetElement.parentElement;
    }
    if (classList.includes('remove-icon')) {
      this.trigger.closeMenu();
      return;
    }
    this.trigger.openMenu();
    this.internalTouched = true;
  }

  handleFocus(event: Event) {
    this.trigger.openMenu();
  }

  onBlur(event: FocusEvent) {
    const target = event.relatedTarget as HTMLElement;
    const classList =
      target && target.classList && Array.from(target.classList);
    if (!classList || !classList.includes('mat-mdc-menu-panel')) {
      this.trigger.closeMenu();
      this.handleError();
      this.handleBlur.emit(event);
    }
  }
}
